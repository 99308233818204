import React, { useEffect, useState } from 'react';
import { AppBar, Grid, IconButton, ButtonGroup, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './selectAddress.css';
import Login from '../../../auth/login';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAddressForOrder } from '../../../../actions/address';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import TaxiAlertIcon from '@mui/icons-material/TaxiAlert';

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: '#FFD64E !important',
    paddingRight: '79px',
    paddingLeft: '118px',
    color: 'black',
    fontSize: '20px',
    fontWeight: 'bold',
    '@media (max-width: 900px)': {
      paddingLeft: 0,
    },
  },
}));

const SelectAddress = () => {
  const { header } = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [logindrawer, setloginDrawer] = useState(true);
  const [addresses, setAddesses] = useState([]);
  const authState = useSelector((state) => state.AuthReducer);

  useEffect(() => {
    if (authState.isLoggedIn) {
      setloginDrawer(false);
      getMyAddress();
    }
  }, [authState]);

  const getMyAddress = () => {
    getAddressForOrder(dispatch, (response) => {
      setAddesses(response.data || []);
    });
  };

  const selectMyAddress = (id) => {
    const orderdetails = JSON.parse(localStorage.getItem('orderdata'));
    if (!orderdetails) {
      navigate('/');
    }
    orderdetails.addressId = id;
    localStorage.setItem('orderdata', JSON.stringify(orderdetails));
    navigate('/pay');
  };

  return (
    <Grid>
      <Login drawer={logindrawer} active={true} toggleDrawer={() => {}} />
      <header>
        <AppBar className={header}>
          <div className="cart_header">
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBackIcon style={{ marginRight: '10px' }}></ArrowBackIcon>
            </IconButton>
            <span>Select Address</span>
          </div>
        </AppBar>
        <div style={{ marginTop: '3.4rem', padding: '16px' }} className="boderAllSides">
          <h5
            style={{
              textDecoration: 'underline',
              color: 'black',
              fontWeight: '600',
              textAlign: 'center',
              fontSize: '14px',
              cursor: 'pointer',
            }}
            onClick={() => navigate('/address/new')}
          >
            Add new Address
          </h5>
        </div>
      </header>
      <div className="address_warpper">
        {addresses.map((item, i) => {
          return (
            <div key={i}>
              <div className="single_address boderAllSides">
                <span className="single_address_name">{item.fullName}</span>
                <p className="single_address_desc">
                  {item.addressLine1}, {`${item.addressLine2 ? `${item.addressLine2},` : ''}`} {item.city}, {item.state}
                </p>
                <p className="single_address_desc">India Mobile: +91 {item.number}</p>
              </div>
              <div className="single_address_action">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '50%',
                    borderRight: '1px solid #CECECE',
                    padding: '8px',
                  }}
                  onClick={() => navigate(`/address/edit/${item._id}`)}
                >
                  <ModeEditIcon sx={{ fontSize: '20px' }}></ModeEditIcon>
                  <span>Edit</span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '50%',
                    padding: '8px',
                    backgroundColor: item?.isAvailable ? 'inherit' : '#F9F9F9',
                  }}
                  onClick={() => {
                    if (item?.isAvailable) {
                      selectMyAddress(item._id);
                    }
                  }}
                >
                  {!item?.isAvailable && <TaxiAlertIcon sx={{ fontSize: '20px', color: '#B32525' }}></TaxiAlertIcon>}
                  {item?.isAvailable && <CheckBoxIcon sx={{ fontSize: '20px' }}></CheckBoxIcon>}
                  <span className='cursor-pointer'>Select</span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Grid>
  );
};

export default SelectAddress;
