import { Axios } from './utils';
import { toast } from 'react-toastify';

const loginUser = (idToken, callback = ({ user, token }) => {}) => {
  return (dispatch) => {
    Axios.post('/auth/singin', { idToken })
      .then((response) => {
        const payload = {
          user: response.data.user,
          token: response.data.token,
        };
        if (!payload.user.additionInfoReq) {
          setTimeout(function () {
            dispatch(setAuth(payload));
          }, 1000);
          toast.success('Logged In');
        }
        callback({ ...payload });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

const logoutUser = () => {
  return (dispatch) => {
    toast.success('Logged Out');
    dispatch(removeAuth());
  };
};

const updateProfile = (update) => {
  return (dispatch) => {
    Axios.put('/user/update-profile', { update })
      .then((response) => {
        console.log(response);
        const payload = {
          user: response.data.user,
          token: response.data.token,
        };
        toast.success('Profile updated');
        dispatch(setAuth(payload));
      })
      .catch((err) => {
        toast.error(err.response.data.message || 'Something went wrong');
      });
  };
};

const updateAdditionalInfo = (addInfo, token, callback = ({ user, token }) => {}) => {
  return (dispatch) => {
    Axios.put('/user/update-additional-info', { update: addInfo }, { headers: { Authorization: token } })
      .then((response) => {
        const payload = {
          user: response.data.user,
          token,
        };
        toast.success('Logged In');
        setTimeout(function () {
          dispatch(setAuth(payload));
        }, 1000);
        callback();
      })
      .catch((err) => {
        toast.error(err?.response?.data.message || 'Something went wrong');
      });
  };
};

const setAuth = (payload) => {
  return {
    type: 'LOGIN',
    payload,
  };
};

const removeAuth = () => {
  return {
    type: 'LOGOUT',
    payload: {},
  };
};

export { loginUser, logoutUser, updateProfile, updateAdditionalInfo };
