import axios from 'axios';

const getAuthToken = ()=> {
  return localStorage.getItem('ltk') || ''
}

export const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || 'https://wedppy.up.railway.app/api/',
  // baseURL: process.env.REACT_APP_API_BASE_URL || 'https://weddppy.herokuapp.com/api',
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': getAuthToken()
  },
  maxContentLength: 20 * 1000 * 1000, // bytes => 5 MB
});

export const AdminAxios = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || 'https://weddppy.herokuapp.com/api/admin',
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': getAuthToken()
  },
  maxContentLength: 20 * 1000 * 1000, // bytes => 5 MB
});
