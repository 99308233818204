import React, { useEffect } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './style.css'
import { Grid } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Social from '../../../soicalMedia';
import { useDispatch, useSelector } from 'react-redux';
import { getCategory } from '../../../../actions/category';
import { createSearchParams, useNavigate } from 'react-router-dom';
export default function CategoryList() {
  const categoryState = useSelector(state => state.CategoryReducer)
  const dispatch = useDispatch()
  const navigate = useNavigate();
  useEffect(() => { 
    window.scrollTo(0, 0)
    dispatch(getCategory())
  }, [])
  console.log(categoryState)
  const handleCatergoryClick = (item) => {
    navigate({
      pathname: `/cat/${item._id}`,
      search: createSearchParams({
        name: item.name
      }).toString()
    });
    // navigate(`/cat/${item._id}`,item);
  };
  return (

    <>
    <Grid container padding={'1rem'} alignItems='center'>
    <Grid item xs={1}>
        <ArrowBackIcon onClick={() => navigate(-1)}/>
      </Grid>
      <Grid >
      <div className='heading'>Rent by Category</div>
      </Grid>

    </Grid>
    {
      categoryState.categories.map((item,i)=>{

  return <>  <Grid container padding={'1rem'} alignItems='center' justifyContent="space-between" onClick={() => handleCatergoryClick(item)}> 
    <Grid item xs={2}>
        <img src={item.image} alt='img' width="100" height="100"/>
      </Grid>
      <Grid >
      <div className='sub-heading'>{item.name}
      <ArrowForwardIosIcon style={{fontWeight:'lighter'}}/>
      </div>
      </Grid>

    </Grid>
    </>
      })
    }


<Social/>
    </>
  )
}
