const loaderMasterStartAction = () => {
  return (dispatch) => {
    dispatch(loaderAction.start());
  };
};

const loaderMasterStopAction = () => {
    return (dispatch) => {
      dispatch(loaderAction.stop());
    };
  };

const loaderAction = {
  start: () => ({ type: 'START_LOADING' }),
  stop: () => ({ type: 'STOP_LOADING' }),
};

export { loaderAction, loaderMasterStartAction, loaderMasterStopAction };
