import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppBar, Grid, IconButton, Button } from '@mui/material';
import { cancelOrder, getOrderDetails } from '../../actions/order';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import './index.css';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: '#FFD64E !important',
    paddingRight: '79px',
    paddingLeft: '118px',
    color: 'black',
    fontSize: '20px',
    fontWeight: 'bold',
    '@media (max-width: 900px)': {
      paddingLeft: 0,
    },
  },
}));

const OrderDetails = () => {
  const { id } = useParams();
  const [order, setOrder] = useState({});
  const { header } = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch()

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = () => {
    getOrderDetails(id, dispatch, (response) => {
      console.log(response.data);
      setOrder(response.data);
    });
  };

  const getDifferenceInDays = (date1, date2) => {
    const a = moment(date1);
    const b = moment(date2);
    return b.diff(a, 'days');
  };

  const getDayAndMonth = (date) => {
    return moment(date).format('D MMMM');
  };

  const formatOrderDate = (date) => {
    return moment(date).format('D MMM YYYY');
  };

  const handleCancelOrder = () => {
    cancelOrder(id, dispatch,(response) => {
      getDetails();
    });
  };

  return (
    <div>
      <Grid>
        <header>
          <AppBar className={header}>
            <div className="orders_header">
              <IconButton onClick={() => navigate('/')}>
                <ArrowBackIcon style={{ marginRight: '10px' }}></ArrowBackIcon>
              </IconButton>
              <span>Order Detail</span>
            </div>
          </AppBar>
        </header>
      </Grid>
      <div>
        <div style={{ padding: '8px 2rem', marginTop: '3.8rem', borderBottom: '3px solid #ccc' }}>
          <div className="order_block_top_det">
            <div className="order_bolck_det_wrap">
              <div className="order_block_det">
                <p className="order_block_id">Order ID</p>
                <p className="order_block_val">{order._id?.slice(1, 8)}...</p>
              </div>
              <div className="order_block_det">
                <p className="order_block_id">Placed on</p>
                <p className="order_block_val">{formatOrderDate(order.orderDate)}</p>
              </div>
              <div className="order_block_det">
                <p className="order_block_id">Payment Mode</p>
                <p className="order_block_val">{order.paymentMode}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="order_block_staus" style={{ border: 'none' }}>
          <div className="order_block_status_left">
            {order.orderStatus === 'PROCESSING' ? (
              <>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <LocalShippingIcon style={{ marginRight: '5px' }}></LocalShippingIcon>
                  <span style={{ fontSize: '16px', color: '#20B2C7', fontWeight: 'bold' }}>Order in Process</span>
                </span>
                <span>
                  <span style={{ fontSize: '14px' }}>Delivery on </span>
                  <span style={{ fontSize: '14px', fontWeight: 'bold' }}>{order.deliveryDate} - 9-11 AM</span>
                </span>
              </>
            ) : order.orderStatus === 'PICKUP_IN_PROCESS' ? (
              <>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <LocalShippingIcon style={{ marginRight: '5px' }}></LocalShippingIcon>
                  <span style={{ fontSize: '16px', color: '#EB5E55', fontWeight: 'bold' }}>Pick Up in Process</span>
                </span>
                <span>
                  <span style={{ fontSize: '14px' }}>Pick up by </span>
                  <span style={{ fontSize: '14px', fontWeight: 'bold' }}>{order.deliveryDate} - 9-11 AM</span>
                </span>
              </>
            ) : order.orderStatus === 'CANCELLED' ? (
              <>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <LocalShippingIcon style={{ marginRight: '5px' }}></LocalShippingIcon>
                  <span style={{ fontSize: '16px', color: 'red', fontWeight: 'bold' }}>Cancelled</span>
                </span>
                <span>
                  {/* <span style={{ fontSize: '14px' }}>Pick up by </span>
                    <span style={{ fontSize: '14px', fontWeight: 'bold' }}>{order.deliveryDate} - 9-11 AM</span> */}
                </span>
              </>
            ) : order.orderStatus === 'ORDERED' ? (
              <>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <LocalShippingIcon style={{ marginRight: '5px' }}></LocalShippingIcon>
                  <span style={{ fontSize: '18px', color: '#FFD64E', fontWeight: 'bold' }}>ORDERED</span>
                </span>
                <span>
                  {/* <span style={{ fontSize: '14px' }}>Pick up by </span>
                                <span style={{ fontSize: '14px', fontWeight: 'bold' }}>{item.deliveryDate} - 9-11 AM</span> */}
                </span>
              </>
            ) : (
              <>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <LocalShippingIcon style={{ marginRight: '5px' }}></LocalShippingIcon>
                  <span style={{ fontSize: '18px', color: '#0D7100', fontWeight: 'bold' }}>Order Completed</span>
                </span>
                <span>
                  <span style={{ fontSize: '14px' }}>Pick up complete on </span>
                  <span style={{ fontSize: '14px', fontWeight: 'bold' }}>{order.deliveryDate}</span>
                </span>
              </>
            )}
          </div>
          <div className="order_block_status_right">
            <span>
              <span style={{ fontSize: '14px' }}>Duration : </span>
              <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
                {getDifferenceInDays(
                  order.dateRange ? order.dateRange[0] : '',
                  order.dateRange ? order.dateRange[1] : '',
                )}{' '}
                days
              </span>
            </span>
            <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
              {getDayAndMonth(order.dateRange ? order.dateRange[0] : '')} -{' '}
              {getDayAndMonth(order.dateRange ? order.dateRange[1] : '')}
            </span>
          </div>
        </div>
        <div>
          <span className="order_item_count">{order.items?.length} items</span>
        </div>
        <div className="order_product_container">
          {order.items?.map((product, key) => {
            return (
              <div className="order_item_static_wrapper" key={key}>
                <div className="order_item_static">
                  <img src={product.image[0]} alt="product" style={{ maxWidth: '70px', marginRight: '0.5rem' }} />
                  <div>
                    <span>{product.name}</span>
                    <span className="order_item_price_wrapper">
                      <span className="order_item_price">₹150 </span>
                      <span className="order_item_price_night"> /days </span>
                    </span>
                  </div>
                </div>
                <div className="order_block_quantity">
                  <CloseIcon style={{ fontSize: '14px', marginRight: '3px' }}></CloseIcon>{' '}
                  <span style={{ fontSize: '14px', fontWeight: 'bold' }}>{product.quantity}</span>
                </div>
              </div>
            );
          })}
        </div>
        <CancelButton variant="contained" onClick={() => handleCancelOrder(order._id)}>
          Cancel Order
        </CancelButton>
        <div className="order_address_container">
          <p style={{ fontSize: '18px' }}>Address: </p>
          <p style={{ fontSize: '12px' }}>
            {order.address?.name?.first}, {order.address?.name?.last}
          </p>
          <p style={{ fontSize: '12px' }}>
            {order.address?.addressLine1}, {`${order.address?.addressLine2 ? `${order.address?.addressLine2},` : ''}`}
          </p>
          <p style={{ fontSize: '12px' }}>
            {order.address?.city}, {order.address?.state} - {order.address?.pinCode}
          </p>
          <p style={{ fontSize: '12px' }}>
            India Mobile: +91 {order.address?.number}
          </p>
        </div>
        <div className="cart_dest_wrap" style={{ margin: '5px 8px 10px 8px' }}>
          <p style={{ fontSize: '16px', fontWeight: 'bold', marginTop: '0' }}>Order Summary</p>
          <p style={{ fontSize: '12px', display: 'flex', justifyContent: 'space-between' }}>
            <span>Order Subtotal:</span>
            <span>₹{order.total}</span>
          </p>
          <p style={{ fontSize: '12px', display: 'flex', justifyContent: 'space-between' }}>
            <span>Estimated Tax:</span>
            <span>₹0</span>
          </p>
          <p style={{ fontSize: '12px', display: 'flex', justifyContent: 'space-between' }}>
            <span>Estimated Shipping:</span>
            <span>₹500</span>
          </p>
          <p style={{ fontSize: '12px', display: 'flex', justifyContent: 'space-between' }}>
            <span>Shipping Discount:</span>
            <span style={{ color: '#3AC48D' }}>₹-500</span>
          </p>
          <p style={{ fontSize: '12px', display: 'flex', justifyContent: 'space-between', fontWeight: 'bold' }}>
            <span>Total:</span>
            <span>₹{order.total}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

const CancelButton = styled(Button)(({ theme }) => ({
  color: '#EB5E55',
  backgroundColor: 'white',
  fontWeight: 700,
  padding: '8px 20px',
  borderRadius: '6px',
  border: '1px solid #ccc',
  minHeight: '2.5rem',
  marginTop: '10px',
  marginLeft: '8px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'white',
  },
}));

export default OrderDetails;
