import { Grid,Link } from '@mui/material'
import { ReactComponent as FbSvg } from '../../assets/fb.svg';
import { ReactComponent as TwitSvg } from '../../assets/Twitter.svg';

import { ReactComponent as YouSVg } from '../../assets/youtube.svg';

import { ReactComponent as InstaSvg } from '../../assets/insta.svg';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react'

export default function Social() {
  return <>

    <Grid container>

      <Grid
        sm={12}
        md={12}
      >
        <h3 style={{ marginTop: '1.5rem', padding: '0 1rem',fontSize:'1.5rem' }}>
          Follow us on:
        </h3>

      </Grid>
    </Grid>
    <Grid container>
      <Grid xs={6}>
        <Link component={RouterLink} to="//www.facebook.com/wedppy" target='_blank' rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'black' }}>
          <div className='soical-card' style={{ padding: '0 1rem' }}>
            <FbSvg />
            <p>
              Facebook
            </p>
          </div>
        </Link>
      </Grid>
      <Grid xs={6}>
        <Link component={RouterLink} to="//twitter.com/wedppy" target='_blank' style={{ textDecoration: 'none', color: 'black' }}>
          <div className='soical-card' style={{ padding: '0 1rem' }}>
            <TwitSvg />
            <p>
              Twitter
            </p>
          </div>
        </Link>
      </Grid>
    </Grid>
    <Grid container paddingTop='0.5rem' marginBottom='1rem'>
      <Grid xs={6}>
        <Link component={RouterLink} to="//www.linkedin.com/company/wedppy" target='_blank' style={{ textDecoration: 'none', color: 'black' }}>
          <div className='soical-card' style={{ padding: '0 1rem' }}>
            <img src='https://static-exp1.licdn.com/sc/h/3loy7tajf3n0cho89wgg0fjre' width='20' />
            <p style={{ marginLeft: '-0.3rem' }}>
              Linkedin
            </p>
          </div>
        </Link>
      </Grid>
      <Grid xs={6}>
        <Link component={RouterLink} to="//www.instagram.com/wedppy/" target='_blank' style={{ textDecoration: 'none', color: 'black' }}>
          <div className='soical-card' style={{ padding: '0 1rem' }}>
            <InstaSvg />
            <p>
              Instagram
            </p>
          </div>
        </Link>
      </Grid>
    </Grid>
  </>

}
