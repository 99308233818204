import { Grid } from '@mui/material';
import React from 'react';
import './hwr.css';

const HowToRent = (props) => {
  return (
    <Grid container className='p-1'>
      <Grid item xs={12} sm={3} className="hwr_body">
        <div style={{ maxHeight: '57px', maxWidth: '57px',width:'128%', backgroundColor: '#fff1d1', borderRadius: '5px' }}></div>
        <div className='hwr_det'>
          <h4 className='hwr_title'>Select Quantity & Date</h4>
          <span className='hwr_des'>
            Select quantity, then in calendar chose from & to date. To change the date
            selection, just start again. Finally, click on add to cart.
          </span>
        </div>
      </Grid>
      <Grid item xs={12} sm={3} className="hwr_body">
        <div style={{ maxHeight: '57px', maxWidth: '57px', width: '128%', backgroundColor: 'rgb(231 243 234)', borderRadius: '5px' }}></div>
        <div className='hwr_det'>
          <h4 className='hwr_title'>Click on Cart & Checkout</h4>
          <span className='hwr_des'>
            Select quantity, then in calendar chose from & to date. To change the date
            selection, just start again. Finally, click on add to cart.
          </span>
        </div>
      </Grid>
      <Grid item xs={12} sm={3} className="hwr_body">
        <div style={{  maxHeight: '57px', maxWidth: '57px', width: '128%', backgroundColor: '#ffe6e0', borderRadius: '5px' }}></div>
        <div className='hwr_det'>
          <h4 className='hwr_title'>Address & Payment</h4>
          <span className='hwr_des'>
            Select quantity, then in calendar chose from & to date. To change the date
            selection, just start again. Finally, click on add to cart.
          </span>
        </div>
      </Grid>
    </Grid>
  );
};

export default HowToRent;
