import React, { useEffect, useState } from 'react';
import { AppBar, Grid, IconButton, ButtonGroup, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { useNavigate, useParams } from 'react-router-dom';
import './addresscreate.css';
import { createNewAddress, deleteAddress, getOneAddress, updateAddress } from '../../../actions/address';
import { checkLocationAvailability } from '../../../actions/locationAvailability';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: '#FFD64E !important',
    paddingRight: '79px',
    paddingLeft: '118px',
    color: 'black',
    fontSize: '20px',
    fontWeight: 'bold',
    '@media (max-width: 900px)': {
      paddingLeft: 0,
    },
  },
}));

const initialState = {
  name: {
    first: '',
    last: '',
  },
  number: '',
  addressLine1: '',
  addressLine2: '',
  pinCode: null,
  city: '',
  state: '',
};

const Addresscreate = () => {
  const { id } = useParams();
  const { header } = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [isEdit] = useState(Boolean(id));
  const [address, setAddress] = useState(initialState);

  useEffect(() => {
    if (isEdit) {
      getOneAddress(id, dispatch, ({ data }) => {
        const curState = {
          name: data.name,
          number: data.number,
          addressLine1: data.addressLine1,
          addressLine2: data.addressLine2,
          pinCode: data.pinCode || null,
          city: data.city,
          state: data.state,
        };
        setAddress(curState);
      });
    }
  }, [isEdit]);

  useEffect(() => {
    if (String(address.pinCode).length === 6) {
      checkLocationAvailability(address.pinCode, (response) => {
        if (response.data.length > 0) {
          const { state, district } = response.data[0];
          setAddress({ ...address, state, city: district })
        }
      });
    }
  }, [address.pinCode]);

  const handleSubmit = () => {
    if (isEdit) {
      updateAddress(address, id, dispatch, (response) => {
        navigate(-1);
        setAddress(...initialState);
      });
    } else {
      createNewAddress(address, dispatch, (response) => {
        navigate(-1);
        setAddress(...initialState);
      });
    }
  };

  const deleteMyAddress = () => {
    deleteAddress(id, dispatch, (resp) => { navigate('/address'); });
  };

  return (
    <Grid>
      <header>
        <AppBar className={header}>
          <div className="cart_header">
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBackIcon style={{ marginRight: '10px' }}></ArrowBackIcon>
            </IconButton>
            <span>{isEdit ? 'Edit' : 'Add'} Address</span>
          </div>
        </AppBar>
      </header>
      <div className="address_edit_warpper" style={{ marginTop: '3.4rem' }}>
        <div className="input_side_by_side">
          <FormControl variant="standard" sx={{ marginBottom: '1rem' }}>
            <InputLabel shrink>First Name</InputLabel>
            <BootstrapInput
              value={address.name.first}
              onChange={(e) => setAddress({ ...address, name: { ...address.name, first: e.target.value } })}
            />
          </FormControl>
          <div style={{ color: '#F9F9F9' }}>d</div>
          <FormControl variant="standard" sx={{ marginBottom: '1rem' }}>
            <InputLabel shrink>Last Name</InputLabel>
            <BootstrapInput
              value={address.name.last}
              onChange={(e) => setAddress({ ...address, name: { ...address.name, last: e.target.value } })}
            />
          </FormControl>
        </div>
        <FormControl variant="standard" sx={{ marginBottom: '1rem' }} fullWidth={true}>
          <InputLabel shrink>Mobile Number</InputLabel>
          <BootstrapInput
            type="number"
            value={address.number}
            onChange={(e) => setAddress({ ...address, number: e.target.value })}
          />
        </FormControl>
        <FormControl variant="standard" sx={{ marginBottom: '1rem' }} fullWidth={true}>
          <InputLabel shrink>Address line 1</InputLabel>
          <BootstrapInput
            value={address.addressLine1}
            onChange={(e) => setAddress({ ...address, addressLine1: e.target.value })}
          />
        </FormControl>
        <FormControl variant="standard" sx={{ marginBottom: '1rem' }} fullWidth={true}>
          <InputLabel shrink>Address line 2</InputLabel>
          <BootstrapInput
            value={address.addressLine2}
            onChange={(e) => setAddress({ ...address, addressLine2: e.target.value })}
          />
        </FormControl>
        <FormControl variant="standard" sx={{ marginBottom: '1rem' }} fullWidth={true}>
          <InputLabel shrink>Pincode</InputLabel>
          <BootstrapInput
            value={address.pinCode}
            type="number"
            onChange={(e) => setAddress({ ...address, pinCode: Number(e.target.value) })}
          />
        </FormControl>
        <div className="input_side_by_side">
          <FormControl variant="standard" sx={{ marginBottom: '1rem' }}>
            <InputLabel shrink>City</InputLabel>
            <BootstrapInput value={address.city} onChange={(e) => setAddress({ ...address, city: e.target.value })} />
          </FormControl>
          <div style={{ color: '#F9F9F9' }}>d</div>
          <FormControl variant="standard" sx={{ marginBottom: '1rem' }}>
            <InputLabel shrink>State</InputLabel>
            <BootstrapInput value={address.state} onChange={(e) => setAddress({ ...address, state: e.target.value })} />
          </FormControl>
        </div>
        <CreateUpdateButton variant="contained" type="submit" fullWidth={true} onClick={() => handleSubmit()}>
          {isEdit ? 'Update' : 'Add'} Address
        </CreateUpdateButton>
        {isEdit && (
          <DeleteButton variant="contained" fullWidth={true} onClick={() => deleteMyAddress()}>
            Delete Address
          </DeleteButton>
        )}
      </div>
    </Grid>
  );
};

export default Addresscreate;

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
    fontWeight: 'bold',
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
    border: '1px solid #CECECE',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const CreateUpdateButton = styled(Button)(({ theme }) => ({
  color: 'black',
  backgroundColor: '#FFD64E',
  fontWeight: 800,
  minWidth: '9rem',
  borderRadius: 10,
  padding: '0.6rem',
  minHeight: '3rem',
  fontSize: '0.7rem',
  '&:hover': {
    backgroundColor: '#FFD64E',
  },
  textDecoration: 'none',
  marginTop: '1.5rem',
}));

const DeleteButton = styled(Button)(({ theme }) => ({
  color: 'black',
  backgroundColor: 'white',
  fontWeight: 800,
  minWidth: '9rem',
  borderRadius: 10,
  padding: '0.6rem',
  minHeight: '3rem',
  fontSize: '0.7rem',
  '&:hover': {
    backgroundColor: 'white',
  },
  textDecoration: 'none',
  marginTop: '1.5rem',
  border: '2px solid black',
}));
