import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Header from '../common/components/Nav/Nav';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PhonelinkIcon from '@mui/icons-material/Phonelink';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SecurityIcon from '@mui/icons-material/Security';
import NotificationsIcon from '@mui/icons-material/Notifications';
import VisibilityIcon from '@mui/icons-material/Visibility';
import BuildIcon from '@mui/icons-material/Build';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { logoutUser } from '../../actions/auth';
import './profile.css';

const Profile = () => {
  const authState = useSelector((state) => state.AuthReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch()
  useEffect(() => {
    if (!authState.isLoggedIn) {
      navigate('/');
    }
  }, [authState]);

  const handleLogout = () => {
    dispatch(logoutUser());
    navigate('/')
  };

  return (
    <Grid>
      <Header />
      <div style={{ marginTop: '4.4rem', position: 'relative' }}>
        <span className="profile_back" onClick={() => navigate(-1)}>
          <ArrowBackIosNewIcon style={{ fontSize: '12px' }}></ArrowBackIosNewIcon>
        </span>
        <div className="profile_header">
          <h2>My Profile</h2>
        </div>
      </div>
      <div>
        {authState?.user?.additionInfoReq ? (
          <div>
            <div className="image_wrapper">
              <img
                className="profile_image"
                src="https://images.pexels.com/photos/1704488/pexels-photo-1704488.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                alt="profile"
              />
            </div>
            <h2 style={{ textAlign: 'center', marginTop: '10px' }}>Incomplete Profile</h2>
          </div>
        ) : (
          <div>
            <div className="image_wrapper">
              <img
                className="profile_image"
                src={authState?.user?.profileUrl || 'https://images.pexels.com/photos/1704488/pexels-photo-1704488.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'}
                alt="profile"
              />
            </div>
            <h2 style={{ textAlign: 'center', marginTop: '10px' }}>{authState?.user?.fullName}</h2>
          </div>
        )}
      </div>
      <div>
          <div className='profileitem_top'  onClick={() => navigate('/edit-profile')}>
              <div className="profileItemIcon"><AccountCircleIcon style={{ color: '#EB5E55' }}></AccountCircleIcon></div>
              <span style={{ fontWeight: 'bold' }}>Account information</span>
          </div>
          <div className='profileitem'>
              <div className="profileItemIcon"><PhonelinkIcon></PhonelinkIcon></div>
          <span className='profile_item_title' onClick={() => navigate('/orders')}>My orders</span>
          </div>
          <div className='profileitem' onClick={() => navigate('/address')}>
              <div className="profileItemIcon"><SecurityIcon
              ></SecurityIcon ></div>
              <span className='profile_item_title'>My Address</span>
          </div>
          <div className='profileitem'>
              <div className="profileItemIcon"><MonetizationOnIcon
              ></MonetizationOnIcon></div>
              <span className='profile_item_title'>Billing</span>
          </div>
          <div className='profileitem'>
              <div className="profileItemIcon"><NotificationsIcon
              ></NotificationsIcon></div>
              <span className='profile_item_title'>Notifications</span>
          </div>
          <div className='profileitem'>
              <div className="profileItemIcon"><VisibilityIcon
              ></VisibilityIcon></div>
              <span className='profile_item_title'>Privacy</span>
          </div>
          <div className='profileitem'>
              <div className="profileItemIcon"><BuildIcon
              ></BuildIcon></div>
              <span className='profile_item_title'>Preferences</span>
          </div>
          <div className='profileitem' onClick={() => handleLogout()}>
              <div className="profileItemIcon"><ExitToAppIcon
              ></ExitToAppIcon></div>
              <span className='profile_item_title'>Logout</span>
          </div>
      </div>
    </Grid>
  );
};

export default Profile;
