import { Grid, Button, Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../category/category.css';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';



const useStyles = makeStyles({
  view_button: {
    padding:'0.25rem'
  },
});

const PopularPackage = ({ catergories }) => {
  const navigate = useNavigate();
  const classes = useStyles();


  const handleCatergoryClick = (id) => {
    navigate(`/sub-cat/${id}`);
  };

  const RenderCategories = ({data}) => {
    return data.map((item, key) => {
      return (
        <Grid
          sm={12}
          md={12}
          key={key}
          className="category_item-img"
          style={{ backgroundColor: item.background, cursor: 'pointer' }}
          onClick={() => handleCatergoryClick(item.id)}
        >
          <img src='https://images.pexels.com/photos/9458756/pexels-photo-9458756.jpeg' className='img' alt="category" />
          <div className="text_warpper">
            <span className="item_desc" style={{fontSize:15,color:'#34B363'}}>Save upto 30% </span>

            <p className="item_title">Birthday Party package</p>
            <span className="item_desc" style={{marginTop:'0.5rem'}}>50 guest 120 items</span>
            <div className='flex' style={{marginTop:'0.5rem',fontWeight:600}}>

            <span className="item_desc" style={{marginRight:'1.25rem',fontSize:18,color:'#EE746D'}}>INR 10,250 </span>
            <span className="item_desc discount" style={{marginRight:'1.25rem',fontSize:18,color:'#959595'}}>INR 17,000 </span>
            </div>

          </div>
        </Grid>
      );
    });
  };

  return (
    <Grid item xs={12}>
      <h3 style={{ marginBottom: '11px',fontSize:'1.25rem' }}>Book by package</h3>
      <RenderCategories data={[1,2,3]} />
      <Box textAlign="center">
      <ColorButton variant="contained">More Packages</ColorButton>

        {/* <Button variant="contained" className="button_view">
          View All
        </Button> */}
      </Box>
    </Grid>
  );
};
// View all button

const ColorButton = styled(Button)(({ theme }) => ({
  color: 'black',
  backgroundColor: '#FFD64E',
  fontWeight:700,
  minWidth:'12rem',
  borderRadius:10,
  padding:'0.75rem',
  minHeight:'3rem',
  marginTop:'1rem',
  marginBottom:'1rem',

  '&:hover':{
  backgroundColor: '#FFD64E',

  }

}));

export default PopularPackage;
