import React, { useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AppBar, Grid, IconButton, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import './order.css';
import { cancelOrder, getMyOrders } from '../../actions/order';
import moment from 'moment';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: '#FFD64E !important',
    paddingRight: '79px',
    paddingLeft: '118px',
    color: 'black',
    fontSize: '20px',
    fontWeight: 'bold',
    '@media (max-width: 900px)': {
      paddingLeft: 0,
    },
  },
}));

const Order = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [orders, setOrders] = useState([]);
  const { header } = useStyles();

  useEffect(() => {
    getOrders()
  },[])

  const getOrders = () => {
    getMyOrders(dispatch, (response) => {
      setOrders(response.data[0].data)
    })
  }

  const getDifferenceInDays = (date1, date2) => {
    const a = moment(date1)
    const b = moment(date2)
    return b.diff(a, 'days')
  }

  const getDayAndMonth = (date) => {
    return moment(date).format('D MMMM')
  }

  const formatOrderDate = (date) => {
    return moment(date).format('D MMM YYYY')
  }

  const handleCancelOrder = (id) => {
    cancelOrder(id, dispatch, (response) => {
      getOrders()
    })
  }

  const RenderOrders = () => {
    return orders.map((item) => {
      return (
        <div className="order_block" key={item._id}>
          <div style={{ backgroundColor: '#F7F7F7', padding: '5px 8px' }}>
            <div className="order_block_top_det">
              <div className="order_bolck_det_wrap">
                <div className="order_block_det">
                  <p className="order_block_id">Order ID</p>
                  <p className="order_block_val">{item._id.slice(1,8)}</p>
                </div>
                <div className="order_block_det">
                  <p className="order_block_id">Placed on</p>
                  <p className="order_block_val">{formatOrderDate(item.orderDate)}</p>
                </div>
                <div className="order_block_det">
                  <p className="order_block_id">Payment Mode</p>
                  <p className="order_block_val">{item.paymentMode}</p>
                </div>
              </div>
              <ArrowForwardIosIcon style={{ fontSize: '15px' }}></ArrowForwardIosIcon>
            </div>
          </div>
          <div className="order_block_staus">
            <div className="order_block_status_left">
              {item.orderStatus === 'PROCESSING' ? (
                <>
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <LocalShippingIcon style={{ marginRight: '5px' }}></LocalShippingIcon>
                    <span style={{ fontSize: '18px', color: '#20B2C7', fontWeight: 'bold' }}>Order in Process</span>
                  </span>
                  <span>
                    <span style={{ fontSize: '14px' }}>Delivery on </span>
                    <span style={{ fontSize: '14px', fontWeight: 'bold' }}>{item.deliveryDate} - 9-11 AM</span>
                  </span>
                </>
              ) : item.orderStatus === 'PICKUP_IN_PROCESS' ? (
                <>
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <LocalShippingIcon style={{ marginRight: '5px' }}></LocalShippingIcon>
                    <span style={{ fontSize: '18px', color: '#EB5E55', fontWeight: 'bold' }}>Pick Up in Process</span>
                  </span>
                  <span>
                    <span style={{ fontSize: '14px' }}>Pick up by </span>
                    <span style={{ fontSize: '14px', fontWeight: 'bold' }}>{item.deliveryDate} - 9-11 AM</span>
                  </span>
                </>
              ) : item.orderStatus === 'CANCELLED' ? (
                <>
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <LocalShippingIcon style={{ marginRight: '5px' }}></LocalShippingIcon>
                    <span style={{ fontSize: '18px', color: 'red', fontWeight: 'bold' }}>Cancelled</span>
                  </span>
                  <span>
                    {/* <span style={{ fontSize: '14px' }}>Pick up by </span>
                    <span style={{ fontSize: '14px', fontWeight: 'bold' }}>{item.deliveryDate} - 9-11 AM</span> */}
                  </span>
                </>
              ) : item.orderStatus === 'ORDERED' ? (
                <>
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <LocalShippingIcon style={{ marginRight: '5px' }}></LocalShippingIcon>
                    <span style={{ fontSize: '18px', color: '#FFD64E', fontWeight: 'bold' }}>ORDERED</span>
                  </span>
                  <span>
                    {/* <span style={{ fontSize: '14px' }}>Pick up by </span>
                    <span style={{ fontSize: '14px', fontWeight: 'bold' }}>{item.deliveryDate} - 9-11 AM</span> */}
                  </span>
                </>
              ) : (
                <>
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <LocalShippingIcon style={{ marginRight: '5px' }}></LocalShippingIcon>
                    <span style={{ fontSize: '18px', color: '#0D7100', fontWeight: 'bold' }}>Order Completed</span>
                  </span>
                  <span>
                    <span style={{ fontSize: '14px' }}>Pick up complete on </span>
                    <span style={{ fontSize: '14px', fontWeight: 'bold' }}>{item.deliveryDate}</span>
                  </span>
                </>
              )}
            </div>
            <div className="order_block_status_right">
              <span>
                <span style={{ fontSize: '14px' }}>Duration : </span>
                <span style={{ fontSize: '14px', fontWeight: 'bold' }}>{getDifferenceInDays(item.dateRange[0], item.dateRange[1])} days</span>
              </span>
              <span style={{ fontSize: '14px', fontWeight: 'bold' }}>{getDayAndMonth(item.dateRange[0])} - {getDayAndMonth(item.dateRange[1])}</span>
            </div>
          </div>
          <div style={{ margin: '10px' }}>
            {item.items.slice(0, 3).map((product, key) => {
              return (
                <div className="order_item_static_wrapper" key={key}>
                  <div className='order_item_static'>
                    <img src={product.image[0]} alt="product" style={{ maxWidth: '80px' }} />
                    <div>
                      <span>{ product.name }</span>
                      <span className="order_item_price_wrapper">
                        <span className="order_item_price">₹150 </span>
                        <span className="order_item_price_night"> /days </span>
                      </span>
                    </div>
                  </div>
                  <div className='order_block_quantity'>
                    <CloseIcon style={{ fontSize: '14px', marginRight: '3px' }}></CloseIcon> <span style={{ fontSize: '14px', fontWeight: 'bold' }}>{ product.quantity }</span>
                  </div>
                </div>
              );
            })}
          </div>
          {
            item.items.length > 3 ? (
              <div className='order_block_more_items'>
                + { item.items.length - 3 } More items
              </div>
            ) : (<></>)
          }
          <div className='order_block_btns'>
            {
              item.orderStatus === 'ORDERED' ?  (
                <CancelButton variant="contained" onClick={() => handleCancelOrder(item._id)}>Cancel Order</CancelButton>
              ) : (
                <ReviewButton variant="contained">Review Order</ReviewButton>
              )
            }
          <ColorButton variant="contained" onClick={() => navigate(`/orders/${item._id}`)}>View Detail{item.status}</ColorButton>
          </div>
        </div>
      );
    });
  };

  return (
    <Grid>
      <header>
        <AppBar className={header}>
          <div className="orders_header">
            <IconButton onClick={() => navigate('/')}>
              <ArrowBackIcon style={{ marginRight: '10px' }}></ArrowBackIcon>
            </IconButton>
            <span>My Order</span>
          </div>
        </AppBar>
      </header>
      <div style={{ marginTop: '4.5rem' }} className="orders_wrapper">
        <RenderOrders />
      </div>
    </Grid>
  );
};

const CancelButton = styled(Button)(({ theme }) => ({
  color: '#EB5E55',
  backgroundColor: 'white',
  fontWeight: 700,
  padding: '8px 20px',
  borderRadius: '6px',
  border: '1px solid #ccc',
  minHeight: '2.5rem',
  marginTop: '10px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'white',
  },
}));

const ReviewButton = styled(Button)(({ theme }) => ({
  color: 'black',
  backgroundColor: 'white',
  fontWeight: 700,
  padding: '8px 20px',
  borderRadius: '6px',
  border: '1px solid #ccc',
  minHeight: '2.5rem',
  marginTop: '10px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'white',
  },
}));

const ColorButton = styled(Button)(({ theme }) => ({
  color: 'black',
  backgroundColor: '#FFD64E',
  fontWeight: 700,
  padding: '8px 60px',
  borderRadius: '6px',
  minHeight: '2.5rem',
  marginTop: '10px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#FFD64E',
  },
}));

export default Order;




// {
//   id: 'INO850099',
//   date: '10 feb 2022',
//   paymentMode: 'Pay On Delivery',
//   status: 'PROCESSING',
//   deliveryDate: '12 feb',
//   eventDaterange: '13-16 feb',
//   duration: '4 days',
//   products: [
//     {
//       id: 1,
//       quantity: 10,
//     },
//     {
//       id: 2,
//       quantity: 30,
//     },
//     {
//       id: 3,
//       quantity: 20,
//     },
//   ],
// },
// {
//   id: 'INO850098',
//   date: '10 feb 2022',
//   paymentMode: 'Pay On Delivery',
//   status: 'PICKUP_IN_PROCESS',
//   deliveryDate: '15 feb',
//   eventDaterange: '16-19 feb',
//   duration: '4 days',
//   products: [
//     {
//       id: 1,
//       quantity: 20,
//     },
//     {
//       id: 2,
//       quantity: 10,
//     },
//     {
//       id: 3,
//       quantity: 50,
//     },
//     {
//       id: 4,
//       quantity: 5,
//     },
//   ],
// },
// {
//   id: 'INO850097',
//   date: '10 feb 2022',
//   paymentMode: 'Pay On Delivery',
//   status: 'COMPLETED',
//   deliveryDate: '15 feb',
//   eventDaterange: '16-19 feb',
//   duration: '4 days',
//   products: [
//     {
//       id: 1,
//       quantity: 20,
//     },
//     {
//       id: 2,
//       quantity: 10,
//     },
//     {
//       id: 3,
//       quantity: 50,
//     },
//   ],
// },
