import React from 'react';
import PublicIcon from '@mui/icons-material/Public';
import './footer.css';

const Footer = (props) => {
    return (
        <div className='footer_body'>
            <h4 className='language_select'><PublicIcon /> English</h4>
            <h4 className='text_center'>Term of use</h4>
            <h4 className='text_center'>Privacy Policy</h4>
            <h4 className='text_center' style={{ color: '#979593' }}>&#169; All Rights Reserved</h4>
        </div>
    )   
}

export default Footer;