import React, { useState, useEffect } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Payment_top from '../../../../assets/payment_top.svg';
import Bank from '../../../../assets/bank.png';
import Card from '../../../../assets/card.png';
import Upi from '../../../../assets/upi.svg';
import Delivery from '../../../../assets/delivery.png';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Login from '../../../auth/login';
import './pay.css';
import { AppBar, Button, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { placeOrder } from '../../../../actions/order';
import { clearCart } from '../../../../actions/cart';
import { ReactComponent as logo } from '../../../../assets/logo.svg';
import axios from 'axios';

export default function Payment() {
  const getAuthToken = () => {
    return localStorage.getItem('ltk') || ''
  }
  const authState = useSelector((state) => state.AuthReducer);
  const { items } = useSelector((state) => state.CartReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [logindrawer, setloginDrawer] = useState(true);
  const [total, setTotal] = useState(0);
  const [paymentType, setPaymentType] = useState('UPI');

  useEffect(() => {
    let curtotal = 0;
    items.forEach((element) => {
      curtotal +=
        element.quantity * element.priceByQuantity[element.quantity < 11 ? '10' : element.quantity < 21 ? '20' : '30'];
    });
    setTotal(curtotal);
  }, [JSON.stringify(items)]);

  useEffect(() => {
    if (authState.isLoggedIn) {
      setloginDrawer(false);
    }
  }, [authState]);

  const proceedhandler = () => {
    const orderdetails = JSON.parse(localStorage.getItem('orderdata'));
    if (!orderdetails) {
      navigate('/');
    }

    orderdetails.paymentMode = paymentType;
    placeOrder(orderdetails, dispatch, (response) => {
      localStorage.removeItem('orderdata');
      dispatch(clearCart());
      navigate('/orders');
    });
  };
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  async function displayRazorpay() {
    const orderdetails = JSON.parse(localStorage.getItem('orderdata'));
    let user = JSON.parse(localStorage.getItem('user'))
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const result = await axios.post("https://weddppy.herokuapp.com/api/order", orderdetails,{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthToken()
      }
    });

    orderdetails.paymentMode = paymentType;

    const { amount, id: order_id, currency } = result.data;
console.log('order_id====>',order_id)
    const options = {
      key: "rzp_test_gzIvQSQyfpBmRA", // Enter the Key ID generated from the Dashboard
      currency: "INR",
      amount:amount.toString(),
      name: 'Wedppy',
      description: 'Test Transaction',
      image: {logo},
      order_id: order_id,

      handler: async function (response) {
        console.log(response)
        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
          ...orderdetails
        };
        console.log(data)

        const result = await axios.post("https://weddppy.herokuapp.com/api/order/success", data,{
           headers: {
             'Content-Type': 'application/json',
             'Authorization': getAuthToken()
           }
         });
        localStorage.removeItem('orderdata');
        dispatch(clearCart());
        navigate('/orders');



      },
      prefill: {
        name: user.fullName,
        email: user.email,
        contact: user.phone,
      },
      notes: {
        address: 'Example Corporate Office',
      },
      theme: {
        color: "#FFD64E",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }
  return (
    <>
      <Login drawer={logindrawer} toggleDrawer={() => {}} />
      <AppBar className="header">
        <div className="orders_header">
          <IconButton
            onClick={() => {
              localStorage.removeItem('orderdata');
              navigate(-1);
            }}
          >
            <ArrowBackIcon style={{ marginRight: '10px' }}></ArrowBackIcon>
          </IconButton>
          <span>Checkout</span>
        </div>
      </AppBar>
      <div className="mobile-first f-column">
        <div className="payment-step">
          <img src={Payment_top} />
        </div>
        <div className="d-flex justify-content-start w-100">
          <h3>Choose your payment options</h3>
        </div>
        <div className="d-flex box">
          <img src={Upi} />
          <div>UPI</div>
          <div className="ml-auto">
            <input type="radio" name="pay" defaultChecked onChange={() => setPaymentType('UPI')} />
          </div>
        </div>
        <div className="d-flex box">
          <img src={Card} />
          <div>Credit /Debit Card</div>
          <div className="ml-auto">
            <input type="radio" name="pay" onChange={() => setPaymentType('CARD')} />
          </div>
        </div>
        <div className="d-flex box">
          <img src={Bank} />
          <div>Net Banking</div>
          <div className="ml-auto">
            <input type="radio" name="pay" onChange={() => setPaymentType('NET_BANKING')} />
          </div>
        </div>
        <div className="d-flex box">
          <img src={Delivery} />
          <div>Pay on Delivery</div>
          <div className="ml-auto">
            <input type="radio" name="pay" onChange={() => setPaymentType('PAY_ON_DELIVERY')} />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-start w-100">
        <h2>Order Summary:</h2>
      </div>

      <div className="cart_dest_wrap">
        <p style={{ fontSize: '12px', display: 'flex', justifyContent: 'space-between' }}>
          <span>Order Subtotal:</span>
          <span>₹{total}</span>
        </p>
        <p style={{ fontSize: '12px', display: 'flex', justifyContent: 'space-between' }}>
          <span>Estimated Tax:</span>
          <span>₹0</span>
        </p>
        <p style={{ fontSize: '12px', display: 'flex', justifyContent: 'space-between' }}>
          <span>Estimated Shipping:</span>
          <span>₹500</span>
        </p>
        <p style={{ fontSize: '12px', display: 'flex', justifyContent: 'space-between' }}>
          <span>Shipping Discount:</span>
          <span style={{ color: '#3AC48D' }}>₹-500</span>
        </p>
        <p style={{ fontSize: '12px', display: 'flex', justifyContent: 'space-between', fontWeight: 'bold' }}>
          <span>Total:</span>
          <span>₹{total}</span>
        </p>
      </div>
      <div className="bottom-section">
        <div className="d-flex justify-content-between m-1 g-2 align-items-center">
          <div>
            <p className="small-text">Incl: Taxes + delivery</p>
            <p className="bold">{total}</p>
          </div>
          <div>
            {/* <ColorButton variant="contained" onClick={() => proceedhandler()}>
              proceed to pay
            </ColorButton> */}
            <ColorButton variant="contained" onClick={() => displayRazorpay()}>
              proceed to pay
            </ColorButton>
          </div>
        </div>
      </div>
    </>
  );
}

const ColorButton = styled(Button)(({ theme }) => ({
  color: 'black',
  backgroundColor: '#FFD64E',
  fontWeight: 700,
  minWidth: '10rem',
  borderRadius: 10,
  padding: '0.75rem',
  minHeight: '3rem',
  '&:hover': {
    backgroundColor: '#FFD64E',
  },
}));
