import React from 'react';
import './smallCategory.css';
import { useNavigate } from 'react-router-dom';

const SmallCategory = ({ catergories }) => {
  const navigate = useNavigate();

  const handleCatergoryClick = (id) => {
    navigate(`/cat/${id}`);
  };

  const RenderCategories = () => {
    return catergories.map((item, index) => {
      return (
        <div className="small_cat_div" key={index} onClick={() => handleCatergoryClick(item._id)}>
          <div className="small_cat_div_img">
            <img src={item.iconImage} width='50' height='50' alt="s_cat" className="small_cat_img" />
          </div>
          <span className="small_cat_txt">{item.name}</span>
        </div>
      );
    });
  };

  return (
    <div className="small_cat_wrapper">
      <RenderCategories />
    </div>
  );
};

export default SmallCategory;
