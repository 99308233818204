import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import './style.css';

export default function Loader() {
  const loaderState = useSelector((state) => state.LoaderReducer);
  useEffect(() => {
    console.log(loaderState);
  }, [loaderState]);

  return (
    <>
      {loaderState && (
        <div className="full-body">
          <div className="lds-dual-ring"></div>
        </div>
      )}
    </>
  );
}
