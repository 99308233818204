import { Grid, Button, ButtonGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Footer from '../common/components/footer/footer';
import Header from '../common/components/Nav/Nav';
import StarIcon from '@mui/icons-material/Star';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import Productchair from '../../assets/productchair.png';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { addToCart } from '../../actions/cart';
import './product.css';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Alert from '@mui/material/Alert';
import { getProductById } from '../../actions/product';
import Carousel from 'react-material-ui-carousel';
import { checkLocationAvailability } from '../../actions/locationAvailability';
import Social from '../soicalMedia';
import RelatedProduct from '../common/components/relatedProduct';
import PopularPackage from '../home/components/Package';
import Frequently from '../common/components/frequentProduct';

const Product = () => {
  const url = window.location.pathname.split('/').pop();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [night] = useState('1');
  const [message, setmessage] = useState('');
  const [errMessage, seterrMessage] = useState('');
  const [pincodeloading, setpincodeloading] = useState(false);
  const [pincode, setpincode] = useState(null);
  const [counter, setCounter] = useState(1);
  const [product, setproduct] = useState({
    name: '',
    description: '',
    categoryId: '',
    subCategoryId: '',
    priceByDays: {},
    priceByQuantity: {},
    stock: '',
    images: [],
    spec: {},
    about: '',
    buyingPrice: 0,
    rating: 0,
  });
  const [productspec] = useState({
    Dimension: 'Size: L40xm x W40xm x H95cm',
    Material: 'Steel',
    Coushning: 'Fabric',
    Color: 'Red',
    Seating: '1 Person',
  });
  const addToCartHandler = () => {
    const Cartproduct = {
      ...product,
      quantity: counter,
    };
    dispatch(addToCart(Cartproduct));
  };

  useEffect(() => {
    getProductById(id, dispatch, (response) => {
      setproduct(response.data);
    });
    window.scrollTo(0, 0)
  }, [url]);

  const handlePinCodeSubmit = (e) => {
    e.preventDefault();
    setpincodeloading(true);
    setmessage('');
    seterrMessage('');
    if (!pincodeloading) {
      checkLocationAvailability(pincode, (response) => {
        if (response.data.length > 0) {
          const { state, district } = response.data[0];
          setmessage(`Great, we are available at ${district}, ${state}`);
        } else {
          seterrMessage('Sorry this location is unservicable !');
        }
        setpincodeloading(false);
      });
    }
  };

  return (
    <>
      <Header />
      <Grid style={{ marginTop: '3.4rem' }}>
        <div className="product_overview">
          <h3 className="product_pg_title">{product.name}</h3>
          <div className="product_pg_desc_warpper">
            <span className="rating_pg_warpper">
              <StarIcon style={{ color: '#FF994E', fontSize: '20px' }} />{' '}
              <span className="rating_pg_number">{product.rating}</span> <span className="rating_pg_count">(98)</span>
            </span>
            <span className="pg_desc_sep">•</span>
            <span className="product_pg_desc">{product.description}</span>
          </div>

          <Carousel autoPlay={false}>
            {product.images.map((item, i) => (
              <div className="product_img_pg_wraps" key={i}>
                <img src={item} alt="product" className="product_img_pg" />
              </div>
            ))}
          </Carousel>
          <div className="product_price_pg_wrap">
            <div>
              <div className="product_price_pg">
                <h1 className="pg_main_price">₹{product.priceByDays ? product.priceByDays['10'] : ''}</h1>
                <span className="pg_main_price_helper"> / day</span>
              </div>
              <span className="product_pg_desc">Buy price : ₹{product.buyingPrice}</span>
            </div>
            <div className="save_list_pg">
              {/* <PlaylistAddIcon /> */}
              {/* <span>Save to list</span> */}
            </div>
          </div>
        </div>
        <div className="pice_desc_wrapper">
          <div className="by_quantity">
            <p className="by_quantity_title">By quantity</p>
            {Object.keys(product.priceByQuantity).map((row, id) => (
              <span className="by_quantity_items">
                <span className="by_quantity_items_name"> {row}</span>
                <span className="by_quantity_items_price">₹{product.priceByQuantity[row]}</span>
              </span>
            ))}
          </div>
          <div className="pice_desc_seper"></div>
          <div className="by_quantity">
            <p className="by_quantity_title">By days</p>
            {Object.keys(product.priceByDays).map((row, id) => (
              <span className="by_quantity_items">
                <span className="by_quantity_items_name">{row} days</span>
                <span className="by_quantity_items_price">₹{product.priceByQuantity[row]}</span>
              </span>
            ))}
          </div>
        </div>
        <div className="location_input_in_wrapper">
          <input
            type="number"
            className="location_search_input"
            onChange={(e) => setpincode(e.target.value)}
            value={pincode}
            placeholder="Check availability, enter pincode"
          />
          <button className="location_search_btn" onClick={(e) => handlePinCodeSubmit(e)}>
            <ArrowForwardIcon />
          </button>
        </div>
        {
          pincodeloading && <Alert severity="info" style={{ margin: '12px' }}>Checking</Alert>
        }
        {message && (
          <Alert style={{ margin: '12px' }} severity="success">
            {message}
          </Alert>
        )}
        {errMessage && (
          <Alert style={{ margin: '12px' }} severity="error">
            {errMessage}
          </Alert>
        )}


        <p className="sub_cat_pg_title">Select Quantity</p>
        <div className="add_to_cart_wrapper">
          <ButtonGroup size="large" variant="outlined" color="secondary">
            {
              <Button
                disabled={counter <= 1}
                onClick={() => {
                  setCounter(counter - 1);
                }}
              >
                -
              </Button>
            }

            {<Button disabled style={{color:'black'}}>{counter}</Button>}

            <Button
              disabled={counter >= 500}
              onClick={() => {
                setCounter(counter + 1);
              }}
            >
              +
            </Button>
          </ButtonGroup>
          <ColorButton variant="contained" onClick={() => addToCartHandler()}>
            Add to cart
          </ColorButton>
        </div>
        <p className="sub_cat_pg_title">About Product</p>
        <p className="about_product">{product.about}</p>

        <p className="sub_cat_pg_title">Specification</p>
        <Grid container>
          <Grid item xs={12}>

        <TableContainer>
          <Table aria-label="customized table">
            <TableBody>
              {Object.keys(product?.spec).map((row, id) => (
                <TableRow key={id} id={id} className={id%2==0 && 'oddrow'}>
                  <TableCell align="left" style={{border:0}}>{row}</TableCell>
                  <TableCell align="right" style={{ fontWeight: 'bold', fontSize: '0.9rem', border: 0 }}>{product.spec[row]}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
          </Grid>
        </Grid>
        <RelatedProduct/>
        <Grid container padding={1}>

        <PopularPackage/>
        </Grid>
        {product.categoryId && <Frequently id={product.categoryId} />}

        <Social/>
        <Footer />
      </Grid>
    </>
  );
};

const ColorButton = styled(Button)(({ theme }) => ({
  color: 'black',
  backgroundColor: '#FFD64E',
  fontWeight: 700,
  minWidth: '100%',
  borderRadius: 10,
  padding: '0.75rem',
  minHeight: '2.5rem',
  marginTop: '10px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#FFD64E',
  },
}));

export default Product;
