import React from 'react';
import { Grid } from '@mui/material';
import './productComponent.css';
import Productchair from '../../../../assets/productchair.png';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import StarIcon from '@mui/icons-material/Star';
import { useNavigate } from 'react-router-dom';

const ProductComponent = ({ id, data }) => {

  const navigate = useNavigate();

  const handleProductClick = (id) => {
    navigate(`/product/${id}`);
  };

  return (
    <Grid item xs={12} sm={6} md={3} className="product_card" onClick={() => {handleProductClick(id)}}>
      <div className="save_list">
        {/* <PlaylistAddIcon /> */}
        {/* <span>Save to list</span> */}
      </div>
      <div className="product_img_wrap">
        <img src={data.images[0]} alt="product" className="product_img" />
      </div>
      <p className='product_title'>{ data.name }</p>
      <span className='product_desc'>{ data.description }</span>
      <div className='price_rating'>
        <span className='price_wrap'><span className='price_span'>₹{ data.priceByDays["10"] }</span> / day</span>
        <span className='rating_wrap'><StarIcon style={{ color: '#FF994E'}} /> <span className='rating_num'>{ data.rating }</span> <span className='rating_count'>(98)</span></span>
      </div>
    </Grid>
  );
};

export default ProductComponent;
