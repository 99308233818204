import React, { useEffect, useState } from 'react';
import { Grid, Button, SwipeableDrawer, RadioGroup, Radio, FormControlLabel, Pagination } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { searchProduct } from '../../actions/product';
import ProductComponent from '../common/components/productComponent/productComponent';
import Header from '../common/components/Nav/Nav';
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import './index.css';
import Filter from '../common/components/filter';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';

const ListingPage = () => {
  const [searchParams] = useSearchParams();
  const [searchValue] = useState(searchParams.get('q'));
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [products, setProducts] = useState([]);
  const [count, setCount] = useState(0);
  const [sort, setSort] = useState('popularity');
  const [sortingItems] = useState(['popularity', 'rent low to high', 'rent high to low', 'top rated products']);
  const [drawer, setDrawer] = useState({ filter: false, sort: false });
  const [filterdrawer, setfilterDrawer] = useState(false);
  const [filter, setFilter] = useState({});
  const dispatch = useDispatch()

  useEffect(() => {
    const params = { searchValue, page, pageSize, sort, filter };
    searchProduct(params, dispatch, (response) => {
      setProducts(response.data[0].data);
      setCount(Math.ceil(response.data[0].count[0].count / pageSize));
    });
  }, [searchValue, page, pageSize, sort, JSON.stringify(filter)]);

  const toggleDrawer = (drawerType, value) => {
    setDrawer({ ...drawer, [drawerType]: value });
  };

  const RenderSortDrawerItems = () => {
    return sortingItems.map((item, key) => {
      return (
        <FormControlLabel
          key={key}
          checked={sort === item}
          value={item}
          control={<CustomRadio />}
          label={item}
          labelPlacement="start"
          style={{ display: 'flex', justifyContent: 'space-between' }}
        />
      );
    });
  };

  return (
    <div>
      <Filter drawerState={filterdrawer} setDrawerState={setfilterDrawer} getFilterValues={setFilter} />
      <Header />
      <div style={{ marginTop: '3.8rem' }}>
        <div className="action_btns">
          <Button variant="contained" onClick={() => toggleDrawer('sort', true)} className="action_bt">
            Sort by {sort} <KeyboardArrowDownIcon />
          </Button>
          <Button variant="contained" className="action_bt" onClick={() => setfilterDrawer(true)}>
            Filter <FilterListIcon style={{ marginLeft: '40px' }} />
          </Button>
          <SwipeableDrawer
            anchor="bottom"
            open={drawer.sort}
            onClose={() => toggleDrawer('sort', false)}
            onOpen={() => toggleDrawer('sort', true)}
            style={{ padding: '20px' }}
          >
            <div style={{ padding: '10px 30px 10px 10px' }}>
              <h4 style={{ padding: '0 10px' }}>Sort By</h4>
              <RadioGroup
                name="sort"
                onChange={(e) => {
                  console.log(e);
                  setSort(e.target.value);
                  toggleDrawer('sort', false);
                }}
                sx={{ textTransform: 'capitalize' }}
              >
                <RenderSortDrawerItems />
              </RadioGroup>
            </div>
          </SwipeableDrawer>
        </div>
        <Grid container className="product_listing">
          {products.map((item, key) => {
            return <ProductComponent id={item._id} data={item} key={key} />;
          })}
        </Grid>
        {products.length === 0 ? (
          <div className="not_found">No Product Found</div>
        ) : (
          <div className="pagination_cont">
            <Pagination count={count} variant="outlined" shape="rounded" onChange={(event, page) => setPage(page)} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ListingPage;

const CustomRadio = styled(Radio)(({ theme }) => ({
  color: '#ffd64e',
  '&.Mui-checked': {
    color: '#ffd64e',
  },
}));

