import React, { useState } from 'react';
import './search.css';
import SearchBackground from '../../../../assets/search-bg.png';
import { Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';

const Search = (props) => {
  const [searchValue, setSearchValue] = useState('');
  const navigate = useNavigate()
  const handleSearch = () => {
    navigate(`/listing/?q=${searchValue}`)
  }
  return (
    <Grid item xs={12} style={{marginTop:'3.4rem'}}>
      <div className="search_div">
        <img src={SearchBackground} alt="search_background" className="search_image" />
        <div className="input_out_wrapper">
          <div className="input_in_wrapper">
            <input className="search_input" onChange={(e) => setSearchValue(e.target.value)} value={searchValue} placeholder="Start your search" />
            <button className="search_btn" onClick={() => handleSearch()}><SearchIcon/></button>
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default Search;
