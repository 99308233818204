import { Grid } from '@mui/material'
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { getProductByCat } from '../../../../actions/product';
import { useDispatch, useSelector } from 'react-redux';
import './style.css'
import { useNavigate } from 'react-router-dom';

export default function Frequently({id}) {
  const [products, setProducts] = useState([]);
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const handleProductClick = (id) => {
    navigate(`/product/${id}`);
  };


  useEffect(() => {
    const params = { page:1, pageSize:10, sort:'popularity', filter:{} };
    getProductByCat({ id, params }, dispatch, (response) => {
      setProducts(response.data[0].data);
      window.scrollTo(0, 0)
    });

  }, [])
  return (
    <>
      <Grid container>
        <Grid item xs="12" margin={1} marginTop={3}>
          <h5 style={{ fontSize: '1.25rem' }}>
            Related products
          </h5>
        </Grid>
      </Grid>

      <Grid container>
<Grid item display='flex' overflow='auto'>
  {
    products.map(item=><>
      <div className='item_card' onClick={() => { handleProductClick(item._id) }}>
        <img src={item.image.main} className='product_img' />
        <div className='item_details'>
          <p className='title text-overflow'>
            {item.name}
          </p>
          <p>
            ₹
            <span>
              {item.priceByDays['10']}
            </span>
            /day
          </p>
        </div>
      </div>
    </>)
  }
</Grid>
      </Grid>
    </>
  )
}
