const items = JSON.parse(localStorage.getItem('items'));

const initialState = {
  items: items || [],
};

const setItems = (items) => {
  localStorage.setItem('items', JSON.stringify(items));
};

const clearCart = () => {
  localStorage.setItem('items', JSON.stringify([]));
  console.log(localStorage.getItem('items'))
};

const CartReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_ITEM':
      const index = state.items.findIndex((item) => item._id === action.payload.product._id);
      if (index >= 0) {
        state.items[index].quantity += action.payload.product.quantity;
      } else {
        state.items.push(action.payload.product);
      }
      setItems(state.items);
      return {
        items: state.items,
      };
    case 'REMOVE_ITEM':
      const index_rm = state.items.findIndex((item) => item.id === action.payload.product.id);
      if (index_rm >= 0) {
        state.items[index_rm].quantity = action.payload.product.quantity;
      }
      setItems(state.items);
      return {
        items: state.items,
      };
    case 'DELETE_ITEM':
      const filtered = state.items.filter((item) => item.id !== action.payload.product.id);
      setItems(filtered);
      return {
        items: filtered,
      };
    case 'CLEAR_CART':
      clearCart();
      return {
        items: [],
      };
    default:
      return state;
  }
};

export default CartReducer;
