import React, { useState } from 'react'
import { makeStyles, styled } from '@mui/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AppBar, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import axios from 'axios';
import './style.css'

import { useDispatch } from 'react-redux';
import { raiseQuery } from '../../../actions/locationAvailability';

const initialState = {
  name: "",
  number: "",
  email: "",
  queryType: "",
  file: "",
  details: ""
}


const useStyles = makeStyles(() => ({
    header: {
      backgroundColor: '#FFD64E !important',
      paddingRight: '79px',
      paddingLeft: '118px',
      color: 'black',
      fontSize: '16px',
      fontWeight: '500',
      '@media (max-width: 900px)': {
        paddingLeft: 0,
      },
    },
  }));

  const handlefilechange = async (e) => {
    const file = e.target.files[0];
    const data = new FormData();
    data.append('file', file);
    data.append('upload_preset', 'ythigm1q');
    data.append('cloud_name', 'dhdv5nqpb');
    const resp = await toast.promise(
      axios.post(
        `https://api.cloudinary.com/v1_1/dhdv5nqpb/image/upload/?api_key=${
          process.env.REACT_APP_CLOUDINARY_KEY
        }&timestamp=${Date.now()}`,
        data,
      ),
      {
        pending: 'Uploading profile image',
        success: 'Uploaded 👌',
        error: 'Upload failed 🤯',
      },
    );
    // setUser({ ...user, profileUrl: resp?.data?.secure_url });
  };

export default function Faq() {
    const { header } = useStyles();
    const navigate = useNavigate();
    const dispatch= useDispatch();
    const [quey, setquey] = useState(initialState);

    const submitQuery = () => {
      raiseQuery(quey, dispatch, (response) => {
        setquey(initialState)
      })
    }

  return (
    <>
    <Grid>
    <header>
      <AppBar className={header}>
        <div className="orders_header">
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon style={{ marginRight: '10px' }}></ArrowBackIcon>
          </IconButton>
          <span>Frequently Asked Questions</span>
        </div>
      </AppBar>
    </header>
    <div style={{ marginTop: '4.5rem' }} className="orders_wrapper">
    <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>How to request return</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Such as s applications available on your iPhone or Android, and any other site on which these terms and conditions appear (collectively, the “Site” or “Sites”).Please review these terms and conditions, which govern your use of Wedppy.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Track your Order</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Cancel Order</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Return/Replace item</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Revise Failed Payment</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Add/update Address</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion disabled>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Check refund status</Typography>
        </AccordionSummary>
      </Accordion>
    </div>
  </Grid>

  <Grid container padding='1rem'>
    <Grid item xs={12}>
        Raise a Query
    </Grid>
  </Grid>

  <Grid container padding='1rem'>
  <Grid item xs={12}>
    <TextField
          id="standard-password-input"
          label="Name*"
          type="text"
          variant="standard"
          fullWidth
          style={{ marginBottom: '15px' }}
          value={quey.name}
          onChange={(e) => { setquey({ ...quey, name: e.target.value }) }}
        />
    </Grid>
    <Grid item xs={12}>
    <TextField
          id="standard-password-input"
          label="Mobile Number*"
          type="text"
          variant="standard"
          fullWidth
          style={{ marginBottom: '15px' }}
          type={'number'}
          value={quey.number}
          onChange={(e) => { setquey({ ...quey, number: e.target.value }) }}
        />
    </Grid>
    <Grid item xs={12}>
    <TextField
          id="standard-password-input"
          label="Email*"
          type="text"
          variant="standard"
          fullWidth
          style={{ marginBottom: '15px' }}
          value={quey.email}
          onChange={(e) => { setquey({ ...quey, email: e.target.value }) }}
        />
    </Grid>
    <Grid item xs={12}>
    <FormControl variant="standard" sx={{ m: 1, minWidth: 120,width:'98%',marginBottom:'1.5rem' }}>
        <InputLabel id="demo-simple-select-standard-label">Select your query type*</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
         
          label="Age"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl>
    </Grid>
    <Grid item xs={12}>
      
    <input
            id="standard-password-input"
            type="file"
            variant="standard"
            fullWidth
            className="custom-file-input"
            style={{ marginBottom: '15px' }}
            onChange={(e) => handlefilechange(e)}
          />
    <TextField
          id="standard-password-input"
          label="Select your Query Type*"
          type="text"
          variant="standard"
          fullWidth
          style={{ marginBottom: '15px' }}
          value={quey.queryType}
          onChange={(e) => { setquey({ ...quey, queryType: e.target.value }) }}
        />
    </Grid>
    <Grid item xs={12}>
    <TextField
          id="standard-password-input"
          label="Attach file (optional)"
          type="text"
          variant="standard"
          fullWidth
          style={{ marginBottom: '15px' }}
          value={quey.file}
          onChange={(e) => { setquey({ ...quey, file: e.target.value }) }}
        />
    </Grid>
    <Grid item xs={12}>
    <TextField
          id="standard-password-input"
          label="Details of the query"
          type="text"
          variant="standard"
          fullWidth
          style={{ marginBottom: '15px' }}
          value={quey.details}
          onChange={(e) => { setquey({ ...quey, details: e.target.value }) }}
        />
    </Grid>
    <Grid item xs={12}>
  <ColorButton onClick={() => submitQuery()}>Proceed to rent</ColorButton>

    </Grid>
  </Grid>

 
  </>
  )
}


const ColorButton = styled(Button)(({ theme }) => ({
    color: 'black !important',
    backgroundColor: '#FFD64E !important',
    fontWeight: 700,
    minWidth: '100% !important',
    borderRadius: 10,
    padding: '0.75rem !important',
    minHeight: '2.5rem !important',
    marginTop: '10px !important',
    textTransform: 'none !important',
    '&:hover': {
      backgroundColor: '#FFD64E !important',
    },
  }));
  