import { Axios } from './utils';
import { toast } from 'react-toastify';
import { loaderMasterStartAction, loaderMasterStopAction } from './loaderAction';

const getProductByCat = ({ id, params }, dispatch, callback) => {
  dispatch(loaderMasterStartAction());
  Axios.get(`/product/cat/${id}`, { params })
    .then((response) => {
      callback(response);
      dispatch(loaderMasterStopAction());
    })
    .catch((err) => {
      console.log(err);
    });
};

const getProductBySubCat = (id, dispatch, callback) => {
  dispatch(loaderMasterStartAction());
  Axios.get(`/product/sub-cat/${id}`)
    .then((response) => {
      callback(response);
      dispatch(loaderMasterStopAction());
    })
    .catch((err) => {
      dispatch(loaderMasterStopAction());
    });
};

const getProductById = (id, dispatch, callback) => {
  dispatch(loaderMasterStartAction());
  Axios.get(`/product/get/${id}`)
    .then((response) => {
      callback(response);
      dispatch(loaderMasterStopAction());
    })
    .catch((err) => {
      dispatch(loaderMasterStopAction());
    });
};

const searchProduct = (params, dispatch, callback) => {
  dispatch(loaderMasterStartAction());
  Axios.get(`/product/search`, { params })
    .then((response) => {
      callback(response);
      dispatch(loaderMasterStopAction());
    })
    .catch((err) => {
      dispatch(loaderMasterStopAction());
    });
};

export { getProductByCat, getProductBySubCat, getProductById, searchProduct };
