const initialState = false;

const LoaderReducer = (state, action) => {
    console.log(action)
  state = state || initialState;
  switch (action.type) {
    case 'START_LOADING':
      return true;
    case 'STOP_LOADING':
      return false;
    default:
      return state;
  }
};

export default LoaderReducer;
