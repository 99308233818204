import { Axios } from './utils';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { loaderMasterStartAction, loaderMasterStopAction } from './loaderAction';

const createNewAddress = (address, dispatch, callback) => {
  dispatch(loaderMasterStartAction())
  Axios({
    method: 'post',
    url: `/address`,
    data: { address },
  })
    .then((response) => {
      toast.success('Address added successfully');
      callback(response);
      dispatch(loaderMasterStopAction())
    })
    .catch((err) => {
      toast.error(err.response.data?.message || 'Something went wrong');
      dispatch(loaderMasterStopAction())
    });
};

const getAddress = (dispatch, callback) => {
  dispatch(loaderMasterStartAction())
  Axios({
    method: 'get',
    url: `/address`,
  })
    .then((response) => {
      callback(response);
      dispatch(loaderMasterStopAction())
    })
    .catch((err) => {
      toast.error(err.response.data?.message || 'Something went wrong');
      dispatch(loaderMasterStopAction())
    });
};

const getOneAddress = (id, dispatch, callback) => {
  dispatch(loaderMasterStartAction())
  Axios({
    method: 'get',
    url: `/address/${id}`,
  })
    .then((response) => {
      callback(response);
      dispatch(loaderMasterStopAction())
    })
    .catch((err) => {
      toast.error(err.response.data?.message || 'Something went wrong');
      dispatch(loaderMasterStopAction())
    });
};

const updateAddress = (address, id, dispatch, callback) => {
  dispatch(loaderMasterStartAction())
  Axios({
    method: 'put',
    url: `/address/${id}`,
    data: { address },
  })
    .then((response) => {
      toast.success('Address updated successfully');
      callback(response);
      dispatch(loaderMasterStopAction())
    })
    .catch((err) => {
      toast.error(err.response.data?.message || 'Something went wrong');
      dispatch(loaderMasterStopAction())
    });
};

const deleteAddress = (id, dispatch, callback) => {
  dispatch(loaderMasterStartAction())
  Axios({
    method: 'delete',
    url: `/address/${id}`,
  })
    .then((response) => {
      toast.success('Address deleted successfully');
      callback(response);
      dispatch(loaderMasterStopAction())
    })
    .catch((err) => {
      toast.error(err.response.data?.message || 'Something went wrong');
      dispatch(loaderMasterStopAction())
    });
};

const getAddressForOrder = (dispatch, callback) => {
  dispatch(loaderMasterStartAction())
  Axios({
    method: 'put',
    url: `/address`,
  })
    .then((response) => {
      callback(response);
      dispatch(loaderMasterStopAction())
    })
    .catch((err) => {
      toast.error(err.response.data?.message || 'Something went wrong');
      dispatch(loaderMasterStopAction())
    });
};

export { createNewAddress, getAddress, getOneAddress, updateAddress, deleteAddress, getAddressForOrder };
