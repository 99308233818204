import { Axios } from "./utils";
import { toast } from "react-toastify";
import { loaderAction } from "./loaderAction";

const getCategory = () => {
    return (dispatch) => {
        dispatch(loaderAction.start())
        Axios.get('/category')
        .then(response => {
            const payload = {
                data: response.data
            }
            dispatch(getCategoryHelper(payload))
            dispatch(loaderAction.stop())
        })
        .catch(err => {
            dispatch(loaderAction.stop())
        })
    }
}

const getCategoryHelper = (payload) => {
    return {
        type: "GET_CATEGORIES",
        payload
    };
};


export { getCategory }