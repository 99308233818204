import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import SearchComponent from './components/search';
import SmallCategory from './components/smallCategory';
import Category from './components/category';
import EventImg from '../../assets/event.png';

import { makeStyles } from '@mui/styles';
import Header from '../common/components/Nav/Nav';
import PopularPackage from './components/Package';
import {  useDispatch, useSelector } from 'react-redux';
import { getCategory } from '../../actions/category';
import Loader from './components/loader';
import { ReactComponent as FbSvg } from '../../assets/fb.svg';
import { ReactComponent as TwitSvg } from '../../assets/Twitter.svg';

import { ReactComponent as YouSVg } from '../../assets/youtube.svg';

import { ReactComponent as InstaSvg } from '../../assets/insta.svg';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import Social from '../soicalMedia';
import Quote from '../common/components/Quote';
import Footer from '../common/components/footer/footer';




const useStyles = makeStyles({
  root: {
    padding:'0.25rem',

  },
});

const Home = (props) => {
  const dispatch = useDispatch()
  const categoryState = useSelector(state => state.CategoryReducer)
  const classes = useStyles();
  const [locationdrawer, setlocationDrawer] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(getCategory())
  }, [])

  return (
    <Grid container className={`${classes.root} mobile-first`}>
      {/* <Loader/> */}
      <Header/>
      <SearchComponent />
      <SmallCategory catergories={categoryState.categories}/>
      <Category catergories={categoryState.categories}/>
      <PopularPackage/>
      <div>
      <Grid
         sm={12}
         md={12}
         sx={{mt:1}}
        >
          <img src={EventImg} className="event-img"/>
          <div className='event-bg'>
            <h3>
              Planning Big Event ?
            </h3>
            <p style={{fontSize:16,marginTop:'0.5rem',marginBottom:'1.25rem'}}>
              Need for a big wedding 500+. We will give you best quality & amazing price
            </p>
            <button className='btn-white' onClick={() => setlocationDrawer(true)}>
              Ask for Free Quote
            </button>
            <Quote drawer={locationdrawer} toggleDrawer={setlocationDrawer}/>
          </div>

        </Grid>
        {/* <Grid
         sm={12}
         md={12}
        >
          <h3>
            Explore by event
          </h3>

        </Grid> */}
        {/* <div className='explore-section'>
          <div className='explore-card'>
            <img src={EventImg} className="explore-img"/>
            <div className='explore-card-bottom'>
              <p >
                Ring Ceremony
              </p>
              <span>
                20 packages
              </span>
            </div>
          </div>
        </div> */}
        {/* theme */}
        {/* <Grid
         sm={12}
         md={12}
        >
          <h3>
          Choose by theme
          </h3>

        </Grid> */}
        {/* <div className='theme-section'>
          <div className='theme-card'>
            <img src={EventImg} className="theme-img"/>
            <div className='theme-card-bottom'>
              <p >
                Ring Ceremony
              </p>
              <span>
                20 packages
              </span>
            </div>
          </div>
        </div> */}
        {/* about */}
        <Grid
         sm={12}
         md={12}
        >
          <h3 style={{marginTop:'1.5rem',padding:'0 1rem'}}>
          About Wedppy
          </h3>

        </Grid>
        <div className='about-section'>
          <div className='about-card' style={{padding:'0 1rem'}}>
           <p>
           Select quantity, then in calendar chose from & to date. To change the date selection, just start again. Finally, clickSelect quantity, then in calendar chose from & to date. To change the date selection, just start again. Finally, clickSelect quantity, then in calendar chose from & to date. To change the date selection, just start again. Finally, click
           </p>
          </div>
        </div>
        {/* Contact us */}
        <Grid
         sm={12}
         md={12}
        >
          <h3 style={{marginTop:'1.5rem',padding:'0 1rem'}}>
          Contact us
          </h3>

        </Grid>
        <div className='about-section'>
          <div className='about-card' style={{padding:'0 1rem'}}>
           <p>
              <span onClick={() => document.location.href = `mailto:wedppy@gmail.com`}></span> wedppy@gmail.com |  <span onClick={() => document.location.href = `tel:+918588881181`}>+(91)-8588-881-181</span>
           </p>
          </div>
        </div>

      </div>
{/* social   */}
<Social/>
{/* <Grid container>

<Grid
         sm={12}
         md={12}
        >
          <h3 style={{marginTop:'1.5rem',padding:'0 1rem'}}>
          Follow us on:
          </h3>

        </Grid>
</Grid>
<Grid container>
  <Grid xs={6}>
  <Link component={RouterLink} to="//www.facebook.com/wedppy" target='_blank' rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'black' }}>
  <div className='soical-card' style={{padding:'0 1rem'}}>
            <FbSvg/>
           <p>
          Facebook
           </p>
          </div>
    </Link>
  </Grid>
  <Grid xs={6}>
  <Link component={RouterLink} to="//twitter.com/wedppy" target='_blank' style={{ textDecoration: 'none', color: 'black' }}>
  <div className='soical-card' style={{padding:'0 1rem'}}>
            <TwitSvg/>
           <p>
          Twitter
           </p>
          </div>
    </Link>
  </Grid>
</Grid>
<Grid container paddingTop='0.5rem' marginBottom='1rem'>
  <Grid xs={6}>
    <Link component={RouterLink} to="//www.linkedin.com/company/wedppy" target='_blank' style={{ textDecoration: 'none', color: 'black' }}>
  <div className='soical-card' style={{padding:'0 1rem'}}>
              <img src='https://static-exp1.licdn.com/sc/h/3loy7tajf3n0cho89wgg0fjre' width='20'/>
           <p style={{marginLeft:'-0.3rem'}}>
          Linkedin
           </p>
          </div>
    </Link>
  </Grid>
  <Grid xs={6}>
  <Link component={RouterLink} to="//www.instagram.com/wedppy/" target='_blank' style={{ textDecoration: 'none', color: 'black' }}>
  <div className='soical-card' style={{padding:'0 1rem'}}>
            <InstaSvg/>
           <p>
          Instagram
           </p>
          </div>
    </Link>
  </Grid>
</Grid> */}
<Footer/>
    </Grid>
  );
};

export default Home;
