const addToCart = (product) => {
    return (dispatch) => {
        dispatch(addToCartHelper(product))
    }
}

const addToCartHelper = (product) => {
    return {
        type: "ADD_ITEM",
        payload: {
            product
        }
    };
};

const removeFromCart = (product) => {
    return (dispatch) => {
        dispatch(removeFromCartHelper(product))
    }
}

const removeFromCartHelper = (product) => {
    return {
        type: "REMOVE_ITEM",
        payload: {
            product
        }
    };
};

const removeWholeProductFromCart = (product) => {
    return (dispatch) => {
        dispatch(removeWholeProductFromCartHelper(product))
    }
}

const removeWholeProductFromCartHelper = (product) => {
    return {
        type: "DELETE_ITEM",
        payload: {
            product
        }
    };
};

const clearCart = () => {
    return (dispatch) => {
        dispatch(clearCartHelper())
    }
}

const clearCartHelper = () => {
    return {
        type: "CLEAR_CART",
        payload: {}
    };
};


export { addToCart, removeFromCart, removeWholeProductFromCart, clearCart }