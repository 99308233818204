import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import Store from "./store";
import Home from "./features/home";
import Listing from "./features/listing";
import SubCategory from "./features/subCategory";
import { ToastContainer } from "react-toastify";
import Product from "./features/product/product";
import Cart from "./features/cart/cart";
import Profile from "./features/profile/profile";
import EditProfile from "./features/profile/edit-profile";
import Orders from "./features/order/order";
import Payment from "./features/common/components/payment";
import OrderDetails from "./features/orderDetail";
import CategoryList from "./features/common/components/category";
import Address from "./features/address/address/address";
import Addresscreate from "./features/address/createEditAddress/addresscreate";
import SelectAddress from "./features/common/components/selectAddress/selectAddress";
import Loader from "./features/home/components/loader";
import Faq from "./features/home/FAQ";
import Admin from "./features/admin";
import ItemList from "./features/admin/itemList";
import SubcategoryList from "./features/admin/subcategoryList";
import CategoryListAdmin from "./features/admin/categoryList";

function App() {
  // const TRACKING_ID = "G-M8NW7F9XVV"; // OUR_TRACKING_ID
  // ReactGA.initialize(TRACKING_ID);
  return (
    <>
    <Provider store={Store}>
      <BrowserRouter>
      <Loader/>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/listing" element={<Listing />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/edit-profile" element={<EditProfile />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/orders/:id" element={<OrderDetails />} />
          <Route path="/cat/:id" element={<SubCategory />} />
          <Route path="/product/:id" element={<Product />} />
          <Route path="/pay" element={<Payment />} />
          <Route path="/cat" element={<CategoryList />} />

          <Route path="/address" element={<Address />} />
          <Route path="/address/new" element={<Addresscreate />} />
          <Route path="/address/edit/:id" element={<Addresscreate />} />
          <Route path="/select-address" element={<SelectAddress />} />
          <Route path="/load" element={<Loader />} />
          <Route path="/faq" element={<Faq />} />
            <Route path="/admin" element={<Admin />} >
              </Route>
              <Route path="/item" element={<ItemList />} />
            <Route path="/category" element={<CategoryListAdmin />} />
              <Route path="/subcategory" element={<SubcategoryList />} />



          <Route path="/*" element={<Home />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer position="top-right" autoClose={5000} theme="light" />
    </Provider>
    </>
  );
}

export default App;
