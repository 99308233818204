import { Grid, Button, Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './category.css';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';




const useStyles = makeStyles({
  view_button: {
    padding:'0.25rem'
  },
});

const PopularCategory = ({ catergories }) => {
  const navigate = useNavigate();
  const classes = useStyles();


  const handleCatergoryClick = (id) => {
    navigate(`/cat/${id}`);
  };

  const RenderCategories = () => {
    return catergories.map((item, key) => {
      return (
        <Grid
          sm={12}
          md={12}
          key={key}
          className="category_item"
          style={{ backgroundColor: item.background, cursor: 'pointer' }}
          onClick={() => handleCatergoryClick(item._id)}
        >
          <div className="text_warpper">
            <div className='flex' style={{alignItems:'center'}}>
            <p className="item_title">{item.name}</p>
            <ArrowForwardIosIcon style={{ color: 'black',fontSize:15 }} />

            </div>

            <span className="item_desc">{item.description}</span>
          </div>
          <img src={item.image} alt="category" className='cat_image'/>
        </Grid>
      );
    });
  };

  return (
    <Grid item xs={12}>
      <h3 style={{ marginBottom: '11px',marginTop: '21px',marginLeft: '11px' }}>Poplur Category</h3>
      <RenderCategories />
      <Box textAlign="center">
      <ColorButton variant="contained">View All</ColorButton>
     
        {/* <Button variant="contained" className="button_view">
          View All
        </Button> */}
      </Box>
    </Grid>
  );
};
// View all button

const ColorButton = styled(Button)(({ theme }) => ({
  color: 'black',
  backgroundColor: '#FFD64E',
  fontWeight:700,
  minWidth:'12rem',
  borderRadius:10,
  padding:'0.75rem',
  minHeight:'3rem',
  fontSize:'1rem',
  '&:hover':{
  backgroundColor: '#FFD64E',

  }
 
}));

export default PopularCategory;
