import React, { useState, useEffect } from 'react';
import { Grid, AppBar, IconButton, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfile } from '../../actions/auth';
import axios from 'axios';
import { toast } from "react-toastify";


const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: '#FFD64E !important',
    paddingRight: '79px',
    paddingLeft: '118px',
    color: 'black',
    fontSize: '20px',
    fontWeight: 'bold',
    '@media (max-width: 900px)': {
      paddingLeft: 0,
    },
  },
}));

const EditProfile = () => {
  const [user, setUser] = useState({
    name: {
      first: '',
      last: '',
    },
    email: '',
    profileUrl: '',
  });
  const authState = useSelector((state) => state.AuthReducer);
  useEffect(() => {
    if (!authState.isLoggedIn) {
      navigate('/');
    } else {
      setUser({
        name: {
          first: authState?.user?.name?.first,
          last: authState?.user?.name?.last,
        },
        email: authState?.user?.email,
        profileUrl: authState?.user?.profileUrl,
      });
    }
  }, [authState]);

  const { header } = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = () => {
    console.log(user);
    dispatch(updateProfile(user));
  };

  const handlefilechange = async (e) => {
    const file = e.target.files[0];
    const data = new FormData();
    data.append('file', file);
    data.append('upload_preset', 'ythigm1q');
    data.append('cloud_name', 'dhdv5nqpb');
    const resp = await toast.promise(
      axios.post(
        `https://api.cloudinary.com/v1_1/dhdv5nqpb/image/upload/?api_key=${
          process.env.REACT_APP_CLOUDINARY_KEY
        }&timestamp=${Date.now()}`,
        data,
      ),
      {
        pending: 'Uploading profile image',
        success: 'Uploaded 👌',
        error: 'Upload failed 🤯',
      },
    );
    setUser({ ...user, profileUrl: resp?.data?.secure_url });
  };

  return (
    <Grid>
      <header>
        <AppBar className={header}>
          <div className="cart_header">
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBackIcon style={{ marginRight: '10px' }}></ArrowBackIcon>
            </IconButton>
            <span>Edit Profile</span>
          </div>
        </AppBar>
      </header>
      <div style={{ marginTop: '3.4rem', padding: '18px' }}>
        <span style={{ fontSize: '25px' }}>Edit Profile</span>
      </div>
      <div style={{ padding: '0 18px' }}>
        <TextField
          id="standard-password-input"
          label="First Name"
          type="text"
          variant="standard"
          fullWidth
          style={{ marginBottom: '15px' }}
          value={user.name.first}
          onChange={(e) => setUser({ ...user, name: { ...user.name, first: e.target.value } })}
        />
        <TextField
          id="standard-password-input"
          label="Last Name"
          type="text"
          variant="standard"
          fullWidth
          style={{ marginBottom: '15px' }}
          value={user.name.last}
          onChange={(e) => setUser({ ...user, name: { ...user.name, last: e.target.value } })}
        />
        <TextField
          id="standard-password-input"
          label="Email"
          type="text"
          variant="standard"
          fullWidth
          style={{ marginBottom: '15px' }}
          value={user.email}
          onChange={(e) => setUser({ ...user, email: e.target.value })}
        />
        <TextField
          id="standard-password-input"
          label="Mobile"
          type="text"
          variant="standard"
          fullWidth
          style={{ marginBottom: '15px' }}
          defaultValue={authState?.user?.phone}
          disabled
        />
        <p>Profile Image</p>
        <div className="image_wrapper">
          <img
            className="profile_image"
            src={
              user?.profileUrl ||
              'https://images.pexels.com/photos/1704488/pexels-photo-1704488.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'
            }
            alt="profile"
          />
          <input
            id="standard-password-input"
            type="file"
            variant="standard"
            fullWidth
            style={{ marginBottom: '15px' }}
            onChange={(e) => handlefilechange(e)}
          />
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '60px' }}>
        <button
          style={{
            padding: '20px 60px',
            backgroundColor: '#FFD64E',
            fontWeight: 'bold',
            fontSize: '18px',
            border: '1px solid #FFD64E',
          }}
          onClick={() => handleSubmit()}
        >
          SAVE
        </button>
      </div>
    </Grid>
  );
};

export default EditProfile;
