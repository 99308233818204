import { Grid } from '@mui/material'
import React from 'react'
import './style.css'

export default function RelatedProduct() {
  return (
    <>
      <Grid container>
        <Grid item xs="12" margin={1} marginTop={3}>
          <h5 style={{ fontSize: '1.25rem' }}>
            Frequently rent together
          </h5>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs="4" md="2">
         <div className='item-card'>
            <img src='https://res.cloudinary.com/dhdv5nqpb/image/upload/v1650593881/hg50xm9zghsfe79nqwoy.png' className='product-img'/>
          <div className='item_details'>
          <p className='title'>
                Hotel Banquet Hall Chair …
          </p>
          <p>
                ₹
            <span>
                  150
            </span>
            /day
          </p>
          </div>
         </div>
        </Grid>

        <Grid item xs={1} className='plus'>
          <div>
            +
          </div>
        </Grid>

        <Grid item xs="4" md="2">
          <div className='item-card'>
            <img src='https://res.cloudinary.com/dhdv5nqpb/image/upload/v1650593881/hg50xm9zghsfe79nqwoy.png' className='product-img' />
            <div className='item_details'>
              <p className='title'>
                Hotel Banquet Hall Chair …
              </p>
              <p>
                ₹
                <span>
                  150
                </span>
                /day
              </p>
            </div>
          </div>
        </Grid>
        <Grid item xs={3} className='right_side'>
          <div className='price'>
            <span className='title' style={{fontSize:'1rem'}}> = ₹300</span>/ day
          </div>
        </Grid>
      </Grid>
    </>
  )
}
