import { Axios } from './utils';
import { toast } from 'react-toastify';
import { loaderMasterStartAction, loaderMasterStopAction } from './loaderAction';

const checkLocationAvailability = (pincode, callback) => {
  Axios({
    method: 'get',
    url: `/generics/check-availability/?pincode=${pincode}`,
  })
    .then((response) => {
      callback(response);
    })
    .catch((err) => {
      toast.error('Something went wrong');
    });
};


const raiseQuery = (query, dispatch, callback) => {
  dispatch(loaderMasterStartAction());
  Axios({
    method: 'post',
    url: '/generics/raise-query',
    data: { query },
  })
    .then((response) => {
      toast.success('Query raised successfully');
      callback(response);
      dispatch(loaderMasterStopAction());
    })
    .catch((err) => {
      toast.error(err.response.data?.message || 'Something went wrong');
      dispatch(loaderMasterStopAction());
    });
};

export { checkLocationAvailability, raiseQuery };
