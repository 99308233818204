import { AppBar, Toolbar, Typography, Button, IconButton, Drawer, Link, MenuItem, Badge } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import LoginIcon from '@mui/icons-material/Login';
import { useSelector, useDispatch } from 'react-redux';
import { logoutUser } from '../../../../actions/auth';
import Login from '../../../auth/login';
import { useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { styled } from '@mui/material/styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import './nav.css';
import { ReactComponent as RentSVG } from '../../../../assets/rent.svg';
import { ReactComponent as OrderSVG } from '../../../../assets/order.svg';
import { ReactComponent as AccountSVG } from '../../../../assets/account.svg';
import { ReactComponent as AboutSVG } from '../../../../assets/info.svg';
import { ReactComponent as ContactSVG } from '../../../../assets/contact.svg';
import { ReactComponent as ReturnSVG } from '../../../../assets/return.svg';
import { ReactComponent as TermsSVG } from '../../../../assets/terms.svg';
import { ReactComponent as LogoutSVG } from '../../../../assets/logout.svg';
import  LogoSVG from '../../../../assets/wedppy2-removebg-preview.png';

import LocationAvailability from '../locationAvailability';

const headersData = [
  {
    label: 'Listings',
    href: '/listings',
  },
  {
    label: 'Mentors',
    href: '/mentors',
  },
  {
    label: 'My Account',
    href: '/account',
  },
  {
    label: 'Profile',
    href: '/profile',
  },
  {
    label: 'My Oders',
    href: '/orders',
  },
];

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: '#FFD64E !important',
    paddingRight: '79px',
    paddingLeft: '118px',
    '@media (max-width: 900px)': {
      paddingLeft: 0,
    },
  },
  logo: {
    fontFamily: 'Work Sans, sans-serif',
    fontWeight: 'bold !important',
    color: 'black',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  menuButton: {
    fontFamily: 'Open Sans, sans-serif',
    color: 'black',

    fontWeight: 700,
    size: '18px',
    marginLeft: '38px',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  drawerContainer: {
    padding: '0',
  },
  navItem1: {
    display: 'flex',
  },
  navItem2: {
    listStyle: 'none',
    marginLeft: '0.75rem',
    fontSize: '0.75rem',
  },
}));

export default function Header() {
  const authState = useSelector((state) => state.AuthReducer);
  const cartState = useSelector((state) => state.CartReducer);
  const navigate = useNavigate();
  const { header, logo, menuButton, toolbar, drawerContainer, navItem1, navItem2 } = useStyles();
  const [logindrawer, setloginDrawer] = useState(false);
  const [locationdrawer, setlocationDrawer] = useState(false);
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
    drawerLoginOpen: false,
  });
  const dispatch = useDispatch();
  const { mobileView, drawerOpen, drawerLoginOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener('resize', () => setResponsiveness());

    return () => {
      window.removeEventListener('resize', () => setResponsiveness());
    };
  }, []);

  useEffect(() => {
    if (authState.isLoggedIn) {
      setloginDrawer(false);
    }
  }, [authState]);

  const displayDesktop = () => {
    return (
      <Toolbar className={toolbar}>
        {femmecubatorLogo}
        <div>
          <ul className={navItem1}>
            <li className={navItem2}>Sahil Yadav</li>
            <li className={navItem2}>Rent by Category</li>
            <li className={navItem2}>My Orders</li>
            <li className={navItem2}>My account</li>
            <li className={navItem2}>About wedppy</li>
            <li className={navItem2} onClick={() => document.location.href = `tel:+918588881181`}>Contact us</li>
            <li className={navItem2}>Return & Refund Policy</li>
            <li className={navItem2}>Terms & Conditions</li>
            <li className={navItem2}>Logout</li>
          </ul>
        </div>
      </Toolbar>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () => setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () => setState((prevState) => ({ ...prevState, drawerOpen: false }));

    const handleLoginDrawerOpen = () => setState((prevState) => ({ ...prevState, drawerLoginOpen: true }));
    const handleLoginDrawerClose = () => setState((prevState) => ({ ...prevState, drawerLoginOpen: false }));

    const handleLogout = () => {
      dispatch(logoutUser());
      setState({ ...state, drawerOpen: false });
    };

    const LogoutComponent = () => {
      return <div onClick={() => handleLogout()}>Logout</div>;
    };

    return (
      <Toolbar>
        <Login drawer={logindrawer} toggleDrawer={setloginDrawer} />
        <LocationAvailability drawer={locationdrawer} toggleDrawer={setlocationDrawer} />
        <IconButton
          {...{
            edge: 'start',
            color: 'inherit',
            'aria-label': 'menu',
            'aria-haspopup': 'true',
            onClick: handleDrawerOpen,
          }}
        >
          <MenuIcon style={{ color: 'black' }} />
        </IconButton>
        <IconButton
          {...{
            edge: 'start',
            color: 'inherit',
            'aria-label': 'menu',
            'aria-haspopup': 'true',
            onClick: () => setlocationDrawer(true),
          }}
        >
          <LocationOnIcon style={{ color: 'black' }} />
        </IconButton>
        <Drawer
          {...{
            anchor: 'left',
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>{getDrawerChoices(LogoutComponent)}</div>
        </Drawer>
        <Drawer
          {...{
            anchor: 'bottom',
            open: drawerLoginOpen,
            onClose: handleLoginDrawerClose,
          }}
        >
          <div className={drawerContainer}>{getDrawerChoices(LogoutComponent)}</div>
        </Drawer>

        <div>{femmecubatorLogoMobo}</div>

        {authState.isLoggedIn ? (
          <IconButton
            {...{
              edge: 'start',
              color: 'inherit',
              'aria-label': 'menu',
              'aria-haspopup': 'true',
              onClick: () => navigate('/profile'),
            }}
          >
            <PersonOutlineIcon style={{ color: 'black' }} />
          </IconButton>
        ) : (
          <IconButton
            {...{
              edge: 'start',
              color: 'inherit',
              'aria-label': 'menu',
              'aria-haspopup': 'true',
              onClick: () => {
                setloginDrawer(true);
              },
            }}
          >
            <LoginIcon style={{ color: 'black' }} />
          </IconButton>
        )}

        <IconButton
          {...{
            edge: 'start',
            color: 'inherit',
            'aria-label': 'menu',
            'aria-haspopup': 'true',
            onClick: () => {
              navigate('/cart');
            },
          }}
        >
          <Badge
            badgeContent={cartState.items.length || 0}
            color="primary"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            overlap="circular"
          >
            <ShoppingBagIcon style={{ color: 'black' }} />
          </Badge>
        </IconButton>
      </Toolbar>
    );
  };

  const getDrawerChoices = (LogoutComponent) => {
    return (
      <>
        <div>
          <div className="user-details">
            {authState.isLoggedIn ? (
              <>
                <IconButton className="svg_icons">
                  <AccountCircleIcon style={{ color: 'black' }} />
                </IconButton>
                <div className="user-name">
                  <p>{authState.user.fullName}</p>
                  <p>{authState.user.phone}</p>
                </div>
                <IconButton
                  {...{
                    edge: 'start',
                    color: 'inherit',
                    'aria-label': 'menu',
                    'aria-haspopup': 'true',
                    onClick: () => {
                      navigate('/profile');
                    },
                  }}
                >
                  <ArrowForwardIosIcon style={{ color: 'black' }} />
                </IconButton>
              </>
            ) : (
              <>
                <div>
                  <LoginSignupButton variant="contained" onClick={() => setloginDrawer(true)}>
                    Login / signup
                  </LoginSignupButton>
                </div>
              </>
            )}
          </div>
          <div>
            <ul className="flex list">
              <li className="flex list-item">
                <div className="icon">
                  {/* <img src='../../../../assets/balloon-dec.png'/> */}
                  <RentSVG />
                </div>
                <div className="flex" style={{ justifyContent: 'space-between' }}>
                  <Link component={RouterLink} to="/cat" style={{ textDecoration: 'none', color: 'black' }}>
                    Rent by Category
                  </Link>
                  <ArrowForwardIosIcon style={{ color: 'black' }} />
                </div>
              </li>
              {authState.isLoggedIn && (
                <>
                  <li className="flex list-item">
                    <div className="icon">
                      <OrderSVG />
                    </div>
                    <Link component={RouterLink} to="/orders" style={{ textDecoration: 'none', color: 'black' }}>
                      My Orders
                    </Link>
                    <div>{/* <ArrowForwardIosIcon style={{ color: 'black' }} /> */}</div>
                  </li>
                  <li className="flex list-item">
                    <div className="icon">
                      <AccountSVG />
                    </div>
                    <Link component={RouterLink} to="/profile" style={{ textDecoration: 'none', color: 'black' }}>
                      My account
                    </Link>
                    <div>{/* <ArrowForwardIosIcon style={{ color: 'black' }} /> */}</div>
                  </li>
                </>
              )}
              <li className="flex list-item">
                <div className="icon">
                  <AboutSVG />
                </div>
                <Link component={RouterLink} to="/" style={{ textDecoration: 'none', color: 'black' }}>
                  About wedppy
                </Link>
                <div>{/* <ArrowForwardIosIcon style={{ color: 'black' }} /> */}</div>
              </li>
              <li className="flex list-item">
                <div className="icon">
                  <ContactSVG />
                </div>
                <Link component={RouterLink} to="/" style={{ textDecoration: 'none', color: 'black' }}>
                  Contact us
                </Link>
                <div>{/* <ArrowForwardIosIcon style={{ color: 'black' }} /> */}</div>
              </li>
              <li className="flex list-item">
                <div className="icon">
                  <ReturnSVG />
                </div>
                <Link component={RouterLink} to="/" style={{ textDecoration: 'none', color: 'black' }}>
                  Return & Refund Policy
                </Link>

                <div>{/* <ArrowForwardIosIcon style={{ color: 'black' }} /> */}</div>
              </li>
              <li className="flex list-item">
                <div className="icon">
                  {/* <img src='../../../../assets/terms.svg'/> */}
                  <TermsSVG />
                </div>
                <Link component={RouterLink} to="/" style={{ textDecoration: 'none', color: 'black' }}>
                  Terms & Conditions
                </Link>
                <div></div>
              </li>
              {authState.isLoggedIn && (
                <li className="flex list-item">
                  <div className="icon">
                    <LogoutSVG />
                  </div>
                  <LogoutComponent />
                  <div></div>
                </li>
              )}
            </ul>
          </div>
        </div>
      </>
    );
    // return headersData.map(({ label, href }) => {
    //   return (
    //     <Link
    //       {...{
    //         component: RouterLink,
    //         to: href,
    //         color: 'inherit',
    //         style: { textDecoration: 'none' },
    //         key: label,
    //       }}
    //     >
    //       <MenuItem>{label}</MenuItem>
    //     </Link>
    //   );
    // });
  };

  const femmecubatorLogoMobo = (
    <div style={{ width: '69vw',display:'flex',justifyContent:'center' }}>
    <Typography variant="h6" component="h1" className={logo} style={{ width: '69vw' }} onClick={() => navigate('/')}>
      WEDPPY
    </Typography>
     {/* <img  src={LogoSVG} style={{width:'8rem',height:'2rem '}}/> */}
    </div>
  );

  const femmecubatorLogo = (
    <Typography variant="h6" component="h1" className={logo} onClick={() => navigate('/')}>
      WEDPPY
    </Typography>
  );

  const getMenuButtons = () => {
    return headersData.map(({ label, href }) => {
      return (
        <Button
          {...{
            key: label,
            color: 'inherit',
            to: href,
            component: RouterLink,
            className: menuButton,
          }}
        >
          {label}
        </Button>
      );
    });
  };

  return (
    <header>
      <AppBar className={header}>{mobileView ? displayMobile() : displayMobile()}</AppBar>
    </header>
  );
}

const LoginSignupButton = styled(Button)(({ theme }) => ({
  color: 'black',
  backgroundColor: 'white',
  fontWeight: 700,
  minWidth: '9rem',
  borderRadius: 10,
  padding: '0.6rem',
  minHeight: '4rem',
  fontSize: '0.7rem',
  '&:hover': {
    backgroundColor: 'white',
  },
  textDecoration: 'none',
  marginTop: '1.5rem',
}));
