import { Axios } from './utils';
import { toast } from 'react-toastify';
import { loaderMasterStartAction, loaderMasterStopAction } from './loaderAction';

const placeOrder = (data, dispatch, callback) => {
  dispatch(loaderMasterStartAction());
  Axios({
    method: 'post',
    url: '/order',
    data: { ...data },
  })
    .then((response) => {
      toast.success('Order placed successfully');
      callback(response);
      dispatch(loaderMasterStopAction());
    })
    .catch((err) => {
      toast.error('Something went wrong');
      dispatch(loaderMasterStopAction());
    });
};

const getMyOrders = (dispatch, callback) => {
  dispatch(loaderMasterStartAction());
  Axios.get(`/order`)
    .then((response) => {
      callback(response);
      dispatch(loaderMasterStopAction());
    })
    .catch((err) => {
      dispatch(loaderMasterStopAction());
    });
};

const cancelOrder = (id, dispatch, callback) => {
  dispatch(loaderMasterStartAction());
  Axios.put(`/order/cancel/${id}`)
    .then((response) => {
      toast.success('Order cancelled successfully');
      callback(response);
      dispatch(loaderMasterStopAction());
    })
    .catch((err) => {
      toast.error('Something went wrong, please contact support!');
      dispatch(loaderMasterStopAction());
    });
};

const getOrderDetails = (id, dispatch, callback) => {
  dispatch(loaderMasterStartAction());
  Axios.get(`/order/details/${id}`)
    .then((response) => {
      callback(response);
      dispatch(loaderMasterStopAction());
    })
    .catch((err) => {
      toast.error('Something went wrong');
      dispatch(loaderMasterStopAction());
    });
};

export { placeOrder, getMyOrders, cancelOrder, getOrderDetails };
