import React, { useState } from 'react';
import { SwipeableDrawer, TextField } from '@mui/material';
import { auth } from '../../firebase';
import { signInWithPhoneNumber, RecaptchaVerifier } from 'firebase/auth';
import { useDispatch } from 'react-redux';
import { loginUser, updateAdditionalInfo } from '../../actions/auth';
import { toast } from 'react-toastify';
import './login.css';
import { Link as RouterLink } from 'react-router-dom';

const Login = ({ drawer, toggleDrawer,active=false }) => {
  console.log(active)
  const dispatch = useDispatch();
  const [number, setNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [showOtpScreem, setShowOtpScreen] = useState(false);
  const [final, setFinal] = useState('');
  const [otherdetailsRequired, setOtherDetailsRequired] = useState(false);
  const [tempToken, setTempToken] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [isLoading, setIsLoading] = useState(false);


  const handlePhoneSubmit = async (e) => {
    setIsLoading(true)
    try {
      e.preventDefault();
      if (number === '' || number.length < 10) {
        setIsLoading(false)
        toast.error('Invaild Phone Number');
      } else {
        const randomId = (Math.random() + 1).toString(36).substring(4);
        document.getElementById('recaptcha-container').innerHTML = `<div id="${randomId}"></div>`;
        let verify = new RecaptchaVerifier(randomId, { size: 'invisible' }, auth);
        verify.render();
        const result = await signInWithPhoneNumber(auth, `+91${number}`, verify);
        setIsLoading(false)
        toast.success('OTP Sent!');
        setFinal(result);
        document.getElementById(randomId).remove();
        setShowOtpScreen(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const verifyOtp = async (e) => {
    setIsLoading(true)
    try {
      e.preventDefault();
      if (otp === null || final === null) {
        setIsLoading(false)
        throw new Error('Enter OTP');
      } else {
        const result = await final.confirm(otp);
        dispatch(
          loginUser(result._tokenResponse.idToken, ({ user, token }) => {
            if (user.additionInfoReq) {
              setTempToken(token);
              setOtherDetailsRequired(true);
            }
            setIsLoading(false)
            setOtp('');
            setNumber('');
            toggleDrawer(false)
            setShowOtpScreen(false);
          }),
        );
      }
    } catch (error) {
      setIsLoading(false)
      if ((error.message = 'Firebase: Error (auth/invalid-verification-code).')) {
        toast.error('Invaild OTP. Please Try Again');
      } else {
        toast.error(error.message);
      }
    }
  };

  const saveAdditionalInfo = (e) => {
    e.preventDefault();
    const userName = name.split(' ');
    const newName = { first: userName[0], last: userName[1] };
    dispatch(
      updateAdditionalInfo({ name: newName, email }, tempToken, () => {
        setTempToken('');
        setOtherDetailsRequired(false);
        setEmail('');
        setName('');
      }),
    );
  };

  return (
    <>
      <SwipeableDrawer
        anchor="bottom"
        open={drawer}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
      >
        <div className="login_drawer">
          {!otherdetailsRequired ? (
            <>
              {!showOtpScreem ? (
                <div>
                  <h3 className="login_title">Login for best experience</h3>
                  <span className="login_desc">Enter your phone number to continue</span>
                  <form className="form_signin" onSubmit={(e) => handlePhoneSubmit(e)}>
                    <TextField
                      fullWidth
                      label="Mobile Number"
                      type={'number'}
                      value={number}
                      onChange={(e) => setNumber(e.target.value)}
                    />
                    <button disabled={number.length !== 10} className="continue_btn">
                      {
                        isLoading ? 'Loading...' : 'Continue'
                      }

                    </button>

                  </form>
                  {
                    active && <button className="continue_btn" style={{marginTop:'-0.5rem',marginBottom:'0.25rem'}} onClick={() => {window.history.go(-1); return false; }}>

                      Back
                    </button>
                  }
                  <span className="login_desc">
                    Having trouble logging in? <span className="change_phone" onClick={() => document.location.href = `tel:+918588881181`}>Contact us</span>
                  </span>
                  <div id="recaptcha-container"></div>
                </div>
              ) : (
                <div>
                  <h3 className="login_title">Verify your Mobile Number</h3>
                  <span className="login_desc">
                    Enter OTP sent to {number}{' '}
                    <span onClick={() => setShowOtpScreen(false)} className="change_phone">
                      Change
                    </span>
                  </span>
                  <form className="form_signin" onSubmit={(e) => verifyOtp(e)}>
                    <TextField
                      fullWidth
                      label="Enter OTP"
                      type={'number'}
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                    />

                    <div id="recaptcha-container"></div>
                      <button disabled={!otp} className="continue_btn">

                        {
                          isLoading ? 'Loading...' : 'Continue'
                        }
                      </button>

                  </form>

                </div>

              )}
            </>
          ) : (
            <>
              <div>
                <h3 className="login_title">Add details for best experience</h3>
                <span className="login_desc">Enter your Email and Name</span>
                <form className="form_signin" onSubmit={(e) => saveAdditionalInfo(e)}>
                  <TextField
                    fullWidth
                    required
                    label="Email"
                    type={'email'}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />

                  <TextField
                    style={{ marginTop: '1rem' }}
                    fullWidth
                    required
                    label="Name"
                    type={'text'}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                    <button className="continue_btn"> {
                      isLoading ? 'Loading...' : 'Continue'
                    }</button>

                </form>
              </div>
            </>
          )}
        </div>
      </SwipeableDrawer>
    </>
  );
};

export default Login;
