import { Grid, Button, SwipeableDrawer, RadioGroup, Radio, FormControlLabel, Pagination } from '@mui/material';
import React, { useState, useEffect } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Header from '../common/components/Nav/Nav';
import './subCategory.css';
import ProductComponent from '../common/components/productComponent/productComponent';
import Footer from '../common/components/footer/footer';
import HowToRent from '../common/components/howToRent/hwr';
import { getSubCategories } from '../../actions/subcategory';
import { getProductByCat } from '../../actions/product';
import Filter from '../common/components/filter';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import Social from '../soicalMedia';
import { getCategory } from '../../actions/category';

const SubCategory = () => {
  const { id } = useParams();
  const dispatch = useDispatch()


  const [subcategories, setSubcategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [sort, setSort] = useState('popularity');
  const [sortingItems] = useState([
    'popularity',
    'rent low to high',
    'rent high to low',
    'top rated products',
  ]);
  const [drawer, setDrawer] = useState({ sort: false });
  const [filterdrawer, setfilterDrawer] = useState(false);
  const [filter, setFilter] = useState({});
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const navigate = useNavigate();
  const categoryState = useSelector(state => state.CategoryReducer)
  const url = window.location.pathname.split('/').pop();
  const [searchparams] = useSearchParams();
  useEffect(() => {
    // getSubCategories(id, dispatch, (response) => {
    //   setSubcategories(response.data);
    // });
    console.log('hi')
    console.log()

    dispatch(getCategory())

  }, []);
  useEffect(() => {
    const params = { page, pageSize, sort, filter };
    getProductByCat({ id, params }, dispatch, (response) => {
      setProducts(response.data[0].data);
      setCount(Math.ceil(response?.data[0]?.count[0]?.count / pageSize));
    });
  }, [url])


  useEffect(() => {
    const params = { page, pageSize, sort, filter };
    getProductByCat({ id, params }, dispatch, (response) => {
      setProducts(response.data[0].data);
      setCount(Math.ceil(response.data[0].count[0].count / pageSize));
    });
  }, [page, pageSize, sort, JSON.stringify(filter)]);

  const toggleDrawer = (drawerType, value) => {
    setDrawer({ ...drawer, [drawerType]: value });
  };

  const handleCatergoryClick = (id) => {
    navigate(`/cat/${id}`);
  };
  const RenderSubCategories = () => {
    return categoryState.categories.map((item, key) => {
      return (
        <Grid item key={key} className="sub_cat_item" xs={4}>
          <div className="item_card" onClick={() => handleCatergoryClick(item._id)}>
            <div className="img_conatiner">
              <img src={item.iconImage} alt="sub_cat" className="sub_cat_image" />
            </div>
            <span className="item_name"> {item.name}</span>
          </div>
        </Grid>
      );
    });
  };

  const RenderSortDrawerItems = () => {
    return sortingItems.map((item, key) => {
      return (
        <FormControlLabel
          key={key}
          checked={sort === item}
          value={item}
          control={<CustomRadio />}
          label={item}
          labelPlacement="start"
          style={{ display: 'flex', justifyContent: 'space-between' }}
        />
      );
    });
  };

  const RenderProducts = () => {
    return products.map((item, key) => {
      return <ProductComponent id={item._id} data={item} key={key} />;
    });
  };

  return (
    <>
      <Header />
      <Filter drawerState={filterdrawer} setDrawerState={setfilterDrawer} getFilterValues={setFilter} />
      <Grid style={{ marginTop: '3.4rem' }}>
        <Grid container className="sub_cat_wrapper">
          <RenderSubCategories />
        </Grid>
        <div className="action_btns">
          <Button variant="contained" onClick={() => toggleDrawer('sort', true)} className="action_bt">
            Sort by {sort} <KeyboardArrowDownIcon />
          </Button>
          <Button variant="contained" className="action_bt" onClick={() => setfilterDrawer(true)}>
            Filter <FilterListIcon style={{ marginLeft: '40px' }} />
          </Button>
          <SwipeableDrawer
            anchor="bottom"
            open={drawer.sort}
            onClose={() => toggleDrawer('sort', false)}
            onOpen={() => toggleDrawer('sort', true)}
            style={{ padding: '20px' }}
          >
            <div style={{ padding: '10px 30px 10px 10px' }}>
              <h4 style={{ padding: '0 10px' }}>Sort By</h4>
              <RadioGroup
                name="sort"
                onChange={(e) => {
                  console.log(e);
                  setSort(e.target.value);
                  toggleDrawer('sort', false);
                }}
                sx={{ textTransform: 'capitalize' }}
              >
                <RenderSortDrawerItems />
              </RadioGroup>
            </div>
          </SwipeableDrawer>
        </div>
        <h3 className="p-1">{searchparams.get("name")}</h3>
        <span className="p-1">{products.length} Results</span>
        <Grid container className="product_listing">
          <RenderProducts />
        </Grid>
        <hr style={{ margin: '21px 10px' }} />
        <div className="pagination_cont">
          <Pagination count={count} variant="outlined" shape="rounded" onChange={(event, page) => setPage(page)} />
        </div>
        <Grid container>
          <Grid item xs={12} marginLeft='0.5rem' marginBottom='1rem'>

        <h5 style={{fontSize:'1.5rem'}}>
          How to Rent
        </h5>
          </Grid>
        </Grid>
        <HowToRent />
        <Social />
        <Footer />
      </Grid>
    </>
  );
};

export default SubCategory;

const CustomRadio = styled(Radio)(({ theme }) => ({
  color: '#ffd64e',
  '&.Mui-checked': {
    color: '#ffd64e',
  },
}));


