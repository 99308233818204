import React, { useState } from 'react';
import { SwipeableDrawer, TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import './locationAvailability.css';
import { checkLocationAvailability } from '../../../../actions/locationAvailability';

const LocationAvailability = ({ drawer, toggleDrawer }) => {
  const [pincode, setpincode] = useState(null);
  const [loading, setloading] = useState(false);
  const [message, setmessage] = useState('');
  const [errMessage, seterrMessage] = useState('');

  const handlePinCodeSubmit = (e) => {
    e.preventDefault();
    setloading(true);
    setmessage('');
    seterrMessage('');
    if (!loading) {
      checkLocationAvailability(pincode, (response) => {
        if (response.data.length > 0) {
          const { state, district } = response.data[0];
          setmessage(`Great, we are available at ${district}, ${state}`);
          setTimeout(() => {
            toggleDrawer(false)
          }, 500);
        } else {
          seterrMessage('Sorry this location is unservicable !');
        }
        setloading(false);
      });
    }
  };

  return (
    <div>
      <SwipeableDrawer
        anchor="bottom"
        open={drawer}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
      >
        <div className="location_drawer">
          <h3 className="location_title">Location availability</h3>
          <span className="location_desc">Enter a pincode to check our availability</span>
          <form className="form_location" onSubmit={(e) => handlePinCodeSubmit(e)}>
            <TextField
              fullWidth
              label="Enter Pincode"
              type={'number'}
              value={pincode}
              onChange={(e) => setpincode(e.target.value)}
            />
            {message && <Alert style={{ margin: '0.5rem 0 0 0' }} severity="success">{message}</Alert>}
            {errMessage && <Alert style={{ margin: '0.5rem 0 0 0' }}  severity="error">{errMessage}</Alert>}
            <button disabled={pincode?.length !== 6} className="continue_btn">
              {loading ? 'Checking' : 'Continue'}
            </button>
          </form>
        </div>
      </SwipeableDrawer>
    </div>
  );
};

export default LocationAvailability;
