import React, { useEffect, useState } from 'react';
import { Grid, Button, SwipeableDrawer, RadioGroup, Radio, FormControlLabel, Pagination } from '@mui/material';
import './filter.css';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';

const initialState = {
  Color: {
    Red: false,
    Blue: false,
    Brown: false,
    Orange: false,
    Green: false,
    Black: false,
    White: false,
    Golden: false,
  },
  Material: { Metal: false },
};

const Filter = ({ drawerState, setDrawerState, getFilterValues }) => {
  const [filter, setfilter] = useState(initialState);
  const [curSel, setCurSel] = useState('Color');

  const applyFilter = () => {
    getFilterValues(filter);
    setDrawerState(false)
  };

  const clearFilter = () => {
    setfilter(initialState);
    applyFilter();
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={drawerState}
      onClose={() => setDrawerState(false)}
      onOpen={() => setDrawerState(true)}
      style={{ padding: '20px' }}
    >
      <div>
        <div className="filter_header">
          <CloseIcon sx={{ marginRight: '10px' }} onClick={() => setDrawerState(false)}></CloseIcon>
          <span style={{ fontSize: '18px', fontWeight: '600' }}>Filters</span>
        </div>
        <div className="filter_group">
          <div className="filter_left">
            {Object.keys(filter).map((item) => {
              return (
                <div
                  className={`${curSel === item ? 'selecetd_filter_cat' : 'filter_cat'}`}
                  onClick={() => setCurSel(item)}
                  style={{ fontSize: '15px', fontWeight: '600' }}
                >
                  {item}
                </div>
              );
            })}
          </div>
          <div className="filter_right">
            {Object.keys(filter[curSel]).map((item, index) => {
              return (
                <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <span style={{ fontSize: '14px', fontWeight: '600' }}>{item}</span>
                  <CustomCheckbox
                    checked={ filter[curSel][item] }
                    onChange={(e) =>{
                      setfilter({
                        ...filter,
                        [curSel]: { ...filter[curSel], [item]: !filter[curSel][item] },
                      })}
                    }
                  ></CustomCheckbox>
                </div>
              );
            })}
          </div>
        </div>
        <div className="filter_btn_sec">
          <div className="clear_filter_btn" onClick={() => clearFilter()}>
            <div>Clear All</div>
          </div>
          <div>
            <button className="filterApply_btn" onClick={() => applyFilter()}>Apply</button>
          </div>
        </div>
      </div>
    </SwipeableDrawer>
  );
};

export default Filter;

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  color: '#ffd64e',
  '&.Mui-checked': {
    color: '#ffd64e',
  },
}));
