import React, { useState } from 'react';
import { Box, Button, SwipeableDrawer, TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import { db, saveTodo } from '../../../../firebase';
import { styled } from '@mui/material/styles';
import {
  ref,
  push
} from 'firebase/database';
import './style.css'
// import './locationAvailability.css';
// import { checkLocationAvailability } from '../../../../actions/locationAvailability';
const userdata = {
  name: '',
  phone: '',
  email: '',
  quote: '',
}
const Quote = ({ drawer, toggleDrawer }) => {
  const [user, setUser] = useState(userdata);
  const [loading, setloading] = useState(false);
  const [message, setmessage] = useState('');
  const [errMessage, seterrMessage] = useState('');
const handleChange=(evt)=>{

  setUser({...user,[evt.target.name]:evt.target.value})

}
  const handlePinCodeSubmit = (e) => {
    e.preventDefault();
    setloading(true);
    setmessage('');
    seterrMessage('');
    console.log('hi')
    // push(ref(db, '/query'), {
    //   status:'pending',
    //   ...user
    // });
    setUser(userdata)
    setTimeout(() => setloading(false), 750);
    setTimeout(() => toggleDrawer(false), 1250);


    }


  return (
    <div>
      <SwipeableDrawer
        anchor="bottom"
        open={drawer}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
      >
        <div className="location_drawer">
          <h3 className="location_title">Ask your Query </h3>
          <span className="location_desc">We'd love to hear from you!</span>
          <form className="form_location" onSubmit={(e) => handlePinCodeSubmit(e)}>
            <TextField
            name='name'
            required
            className='input-q'
              fullWidth
              label="Enter your name"
              type={'text'}
              value={user.name}
              onChange={(e) => handleChange(e)}
            />
            <TextField
            name='phone'
              className='input-q'
              required
              fullWidth
              label="Enter your phone number"
              type={'number'}
              value={user.phone}
              onChange={(e) => handleChange(e)}
            />
            <TextField
            name='email'
              fullWidth
              className='input-q'
              required
              label="Enter your email"
              type={'email'}
              value={user.email}
              onChange={(e) => handleChange(e)}
            />
            <TextField
            name='quote'
              className='input-q'
              fullWidth
              required
              label="Ask your qurey"
              type={'text'}
              value={user.quote}
              onChange={(e) => handleChange(e)}
            />
            {/* {message && <Alert style={{ margin: '0.5rem 0 0 0' }} severity="success">{message}</Alert>}
            {errMessage && <Alert style={{ margin: '0.5rem 0 0 0' }} severity="error">{errMessage}</Alert>} */}
<Box display='flex' justifyContent='center'>

              <ColorButton type='submit' variant="contained" className={loading ? 'btn sending': 'btn '}>
              {loading ? 'Loading...     ' : 'Ask us'}
              </ColorButton>
  </Box>
          </form>
        </div>
      </SwipeableDrawer>
    </div>
  );
};

export default Quote;
const ColorButton = styled(Button)(({ theme }) => ({
  color: 'black',
  backgroundColor: '#FFD64E',
  fontWeight: 700,
  minWidth: '12rem',
  borderRadius: 10,
  padding: '0.75rem',
  minHeight: '3rem',
  marginTop: '1rem',
  marginBottom: '1rem',

  '&:hover': {
    backgroundColor: '#FFD64E',

  }

}));
