import React, { useEffect, useState } from 'react';
import { AppBar, Grid, IconButton, ButtonGroup, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Productchair from '../../assets/productchair.png';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { removeFromCart, removeWholeProductFromCart } from '../../actions/cart';
import { DateRangePicker } from 'rsuite';
import { toast } from 'react-toastify';
import './cart.css';
import Social from '../soicalMedia';
import Footer from '../common/components/footer/footer';
const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: '#FFD64E !important',
    paddingRight: '79px',
    paddingLeft: '118px',
    color: 'black',
    fontSize: '20px',
    fontWeight: 'bold',
    '@media (max-width: 900px)': {
      paddingLeft: 0,
    },
  },
}));

const Cart = () => {
  const { combine, beforeToday } = DateRangePicker;
  const { header } = useStyles();
  const navigate = useNavigate();
  const { items } = useSelector((state) => state.CartReducer);
  const dispatch = useDispatch();
  const [total, setTotal] = useState(0);
  const [date, setDate] = useState([]);

  const calculatePrice = (quan, price) => {
    return price * quan;
  };

  useEffect(() => {
    let curtotal = 0;
    items.forEach((element) => {
      curtotal +=
        element.quantity * element.priceByQuantity[element.quantity < 11 ? '10' : element.quantity < 21 ? '20' : '30'];
    });
    setTotal(curtotal);
  }, [JSON.stringify(items)]);

  const updateCartProduct = (id, newQuantity) => {
    dispatch(removeFromCart({ id, quantity: newQuantity }));
  };

  const deleteItemFromCart = (id) => {
    dispatch(removeWholeProductFromCart({ id }));
  };

  const proceedhandler = () => {
    if (date.length === 0) {
      toast.error('Select event day(s)');
    } else if (items.length === 0) {
      toast.error('No item in cart to proceed');
    } else {
      const orderdetails = {
        dateRange: date,
      };
      const products = items.map((item) => {
        const purchasePrice = item.priceByQuantity[item.quantity < 11 ? '10' : item.quantity < 21 ? '20' : '30']
        item.total = calculatePrice(item.quantity, purchasePrice);
        item.purchasePrice = purchasePrice;
        return item;
      });
      orderdetails.items = products;
      localStorage.setItem('orderdata', JSON.stringify(orderdetails));
      navigate('/select-address');
    }
  };

  const handleDateChange = (e) => {
    const startDate = new Date(e[0]);
    const endDate = new Date(e[1]);
    setDate([startDate, endDate]);
  };

  return (
    <Grid>
      <header>
        <AppBar className={header}>
          <div className="cart_header">
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBackIcon style={{ marginRight: '10px' }}></ArrowBackIcon>
            </IconButton>
            <span>Cart</span>
          </div>
        </AppBar>
      </header>
      <div style={{ marginTop: '3.4rem' }} className="my_cart_wrapper">
        <div className="cart_title_warp">
          <h4 className="cart_title">My Cart</h4>
          <span className="pd_quantiy">{items.length} items</span>
        </div>
        <div className="my_cart_total">₹{total.toFixed(2)}</div>
      </div>
      <div className="cart_items_warp" style={{ padding: '10px' }}>
        {items.map((item, id) => {
          return (
            <div key={id}>
              <div className="cart_item">
                <div className="product_item_static">
                  <img src={item.images[0]} alt="product" style={{ maxWidth: '80px', marginRight: '5px' }} />
                  <div>
                    <span className="cart_item_name">{item.name}</span>
                    <span className="cart_item_price_wrapper">
                      <span className="cart_item_price">
                        ₹{item.priceByQuantity[item.quantity < 11 ? '10' : item.quantity < 21 ? '20' : '30']}
                      </span>
                      <span className="cart_item_price_night">/piece</span>
                      <span className="cart_item_price_discount">20% off</span>
                    </span>
                  </div>
                </div>
                <hr />
                <div className="product_item_var">
                  <IconButton
                    style={{ backgroundColor: '#FFD64E', borderRadius: '5px', padding: '2px' }}
                    onClick={() => deleteItemFromCart(item.id)}
                  >
                    <CloseIcon style={{ fontSize: '15px', color: 'black' }}></CloseIcon>
                  </IconButton>
                  <span>
                    <span className="cart_item_total">Total</span>{' '}
                    <span className="cart_item_total_price">
                      ₹
                      {calculatePrice(
                        item.quantity,
                        item.priceByQuantity[item.quantity < 11 ? '10' : item.quantity < 21 ? '20' : '30'],
                      )}
                    </span>
                  </span>
                  <ButtonGroup size="small" variant="outlined" color="secondary">
                    {
                      <Button
                        disabled={item.quantity <= 1}
                        onClick={() => {
                          updateCartProduct(item.id, item.quantity - 1);
                        }}
                      >
                        -
                      </Button>
                    }

                    {<Button disabled>{item.quantity}</Button>}

                    <Button
                      disabled={item.quantity >= 500}
                      onClick={() => {
                        updateCartProduct(item.id, item.quantity + 1);
                      }}
                    >
                      +
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            </div>
          );
        })}
        <span>EVENT DAYS</span>
        <div className="cart_event_picker">
          <DateRangePicker placeholder="Select Start date to End Date" oneTap showOneCalendar onChange={(e) => handleDateChange(e)} disabledDate={beforeToday()} />
        </div>
        {/* <div className="promocode_wrap">
          <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '5px' }}>Have a Promo Code?</p>
          <span style={{ fontSize: '12px' }}>Only one promo code may be used per order.</span>
          <div className="input_in_wrapper_cart">
            <input className="search_input_cart" placeholder="Enter Code" />
            <button className="search_btn_cart">Apply</button>
          </div>
        </div> */}
        <div className="cart_dest_wrap">
          <p style={{ fontSize: '16px', fontWeight: 'bold', marginTop: '0' }}>Order Summary</p>
          <p style={{ fontSize: '12px', display: 'flex', justifyContent: 'space-between' }}>
            <span>Order Subtotal:</span>
            <span>₹{total}</span>
          </p>
          <p style={{ fontSize: '12px', display: 'flex', justifyContent: 'space-between' }}>
            <span>Estimated Tax:</span>
            <span>₹0</span>
          </p>
          <p style={{ fontSize: '12px', display: 'flex', justifyContent: 'space-between' }}>
            <span>Estimated Shipping:</span>
            <span>₹500</span>
          </p>
          <p style={{ fontSize: '12px', display: 'flex', justifyContent: 'space-between' }}>
            <span>Shipping Discount:</span>
            <span style={{ color: '#3AC48D' }}>₹-500</span>
          </p>
          <p style={{ fontSize: '12px', display: 'flex', justifyContent: 'space-between', fontWeight: 'bold' }}>
            <span>Total:</span>
            <span>₹{total}</span>
          </p>
        </div>
        <ColorButton onClick={() => proceedhandler()}>Proceed to rent</ColorButton>
        <Social/>
        <Footer/>
      </div>
    </Grid>
  );
};

const ColorButton = styled(Button)(({ theme }) => ({
  color: 'black',
  backgroundColor: '#FFD64E',
  fontWeight: 700,
  minWidth: '100%',
  borderRadius: 10,
  padding: '0.75rem',
  minHeight: '2.5rem',
  marginTop: '10px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#FFD64E',
  },
}));

export default Cart;
